import { createContext } from "react"

export const CustomerReportPageContext = createContext({
  hasProjectSettingsPermission: false,
  customerReportsData: [] as any,
  isLoading: false,
  onSortingChange: (key: string, value: string) => {},
  onTagFilterChange: (tag: string) => {},
  onRepFilterChange: (rep: string) => {},
  onDateChange: (startDate: any, endDate: any) => {},
  onSearch: (e: string) => {},
  filters: {},
  searchValue: '',
  refetch: () => {},
  Representatives: [],
  customerTags: [],
})

export const SalesRepReportPageContext = createContext({
  hasProjectSettingsPermission: false,
  salesRepReportsData: [] as any,
  isLoading: false,
  onSortingChange: (key: string, value: string) => {},
  onProjectChange: (id: any) => {},
  onSearch: (e: string) => {},
  filters: {},
  searchValue: '',
  refetch: () => {},
})

export const ProductsReportPageContext = createContext({
  hasProjectSettingsPermission: false,
  productsReportsData: [] as any[],
  isLoading: false,
  onSortingChange: (key: string, value: string) => {},
  onSearch: (e: string) => {},
  searchValue: '',
  filters: {},
  refetch: () => {},
})

export const SuggestedCustomerReportPageContext = createContext({
  hasProjectSettingsPermission: false,
  suggestedCustomersData: [] as any,
  isLoading: false,
  onSortingChange: (key: string, value: string) => {},
  onRepFilterChange: (rep: string) => {},
  onSearch: (e: string) => {},
  filters: {},
  searchValue: '',
  refetch: () => {},
  Representatives: [],
  updateCustomerTags: (payload: any, customerId: any) => {},
})

export const GlobalProductsContext = createContext({
  hasProjectSettingsPermission: false,
  GlobalProductsData: [] as any,
  isLoading: false,
  onSortingChange: (key: any, value: any) => {},
  onRepFilterChange: (rep: string) => {},
  onSearch: (e: string) => {},
  filters: {} as any,
  searchValue: '',
  refetch: () => {},
  Representatives: [],
  updateCustomerTags: (payload: any, customerId: any) => {},
  boProductsDropdownList: [],
  isLoadingBoProductsDropdownList: false,
  refetchBoProductsDropdownList: () => {},
  handleProductOptionChange: (selectedOptions: {}) => {},
  AllStateData: [] as any,
  isLoadingStates: false,
  onStateFilterChange: (selectedOption: string) => {},
  isFetchingCsvData: false,
  onDownloadCsv: (filters: any) => {},
  onApplySortingOnProduct: (ids: any) => {},
})
