import {useState} from 'react'
import useApi from '../../../services/useApi'

const useReplenishmentOperations = () => {
  const Api = useApi()
  const [isLoading, setIsLoading] = useState(false)

  const saveTableColumns = (columns: any, type: any) => {
    const payload = {
      columns: columns?.join(','),
      type: type,
    }

    return Api.get('/analytics/replenishment/columns', {
      setLoading: setIsLoading,
      filters: payload,
    })
  }

  return {
    saveTableColumns: saveTableColumns,
    isLoading: isLoading,
  }
}

export default useReplenishmentOperations
