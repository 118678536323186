import {Bar} from 'react-chartjs-2'

interface BarChartProps {
  options: object
  data: any
  height?: number
}

function BarChart(props: BarChartProps) {
  const {data, options, height = 300} = props

  return <Bar id='chart' data={data} options={options} height={height} />
}

export default BarChart
