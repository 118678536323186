import {Link, useSearchParams, useLocation} from 'react-router-dom'
import {NavigationTabsProps} from '../NavigationTypes'
import {KTSVG} from '../../../_metronic/helpers'

export const NavigationTabs = ({
  navigations,
  className,
  disableSearchParams,
  baseUrl,
  paramKey,
}: NavigationTabsProps) => {
  const [searchParams] = useSearchParams()
  const location = useLocation()

  return (
    <ul
      className={`nav nav-stretch nav-line-tabs nav-line-tabs-2x fs-5 fw-bold flex-nowrap mb-10 ${className}`}
    >
      {navigations.map((item) => {
        const to = disableSearchParams
          ? item.key === '/'
            ? baseUrl.replace(/\/$/, '')
            : `${baseUrl.replace(/\/$/, '')}/${item.key}`
          : `${baseUrl}?${paramKey}=${item.key}`

        const isActive = disableSearchParams
          ? location.pathname === to
          : searchParams.get(paramKey) === item.key

        return (
          <li className={`nav-item ${item.className || ''}`}>
            <Link
              className={`nav-link text-active-primary cursor-pointer ${isActive ? 'active' : ''} ${
                item.disabled ? 'disabled' : ''
              }`}
              to={to}
              onClick={(e) => item.disabled && e.preventDefault()}
            >
              {item.icon && <KTSVG path={item.icon} className='me-2' />}
              {item.label}
            </Link>
          </li>
        )
      })}
    </ul>
  )
}
