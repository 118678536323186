import {useContext} from 'react'
import {WebVisitorsContext} from '../../context'
import {DynamicTable} from '../../../../components/DynamicTable'
import WebVisitorsRow from './WebVisitorsRow'
import {parseWebVisitorRow, WEB_VISITORS_COLUMNS} from '../../utils'

const WebVisitorsTable = () => {
  const {visitors, isLoading, onSortingChange} = useContext(WebVisitorsContext)

  return (
    <DynamicTable
      data={visitors}
      sortableColumns={WEB_VISITORS_COLUMNS}
      loading={isLoading}
      noDataMessage='No web visitors found'
      onSortingChange={onSortingChange}
      tableClass='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-2'
      TableRow={WebVisitorsRow}
      rowParsingFunction={parseWebVisitorRow}
    />
  )
}

export default WebVisitorsTable
