import Api from '../../../services/Api'

export const getTableData = (url: string, filters: any) => {
  return Api.get(`${url}`, filters).then((d: any) => {
    return d
  })
}

export const getReorderTableData = (url: string, filters: any) => {
  return Api.get(`${url}`, filters).then((d: any) => {
    return d
  })
}

export const getFilters = async (filters: any) => {
  const url = `/analytics/replenishment/query/filters`
  const response = await Api.get(url, filters)
  return response
}

export const getDashboardFilters = async (filters: any) => {
  const url = `/analytics/replenishment/dashboard/filters`
  const response = await Api.get(url, filters)
  return response
}

export async function deleteBlog(b_id: string) {
  const url = `/cms/blogs/${b_id}`
  const response = await Api.delete(url, b_id, null, null)
  return response
}

export async function updateBlog(data: any) {
  const name_data = {
    name: data.name,
    description: data.description,
    status: data.status,
    status_update: data.status_update,
  }
  const b_id = data.id
  const url = `/cms/blogs/${b_id}`
  const response = await Api.put(url, name_data, null, null)
  return response
}

export async function createBlog(data: any) {
  const url = `/cms/blogs`
  const response = await Api.post(url, data, null, null)
  return response
}

export async function updatePOQty(data: any) {
  const url = `/analytics/replenishment/po/reorder`
  const response = await Api.post(url, data, null, null)
  return response
}

export async function updateRowData(url: string, data: any) {
  const response = await Api.patch(url, data, null, null)
  return response
}

export async function clearAllReorders() {
  const url = `/analytics/replenishment/po/reorder`
  const response = await Api.delete(url, {}, null, null)
  return response
}

export const getCsvData = (filters: any) => {
  const url = '/analytics/replenishment/po/reorder/csvdata'
  return Api.get(`${url}`, filters).then((d: any) => {
    return d?.data
  })
}

export const getChildAggregationData = ({filters}: any) => {
  const url = '/analytics/replenishment/aggregate/child/data'
  return Api.get(`${url}`, filters).then((d: any) => {
    return d
  })
}

export async function createSupplierMapping(data: any) {
  const url = `/analytics/replenishment/supplier/mapping`
  const response = await Api.post(url, data, null, null)
  return response
}

export async function updateSupplierMapping(data: any) {
  const url = `/analytics/replenishment/sppliers`
  const response = await Api.put(url, data, null, null)
  return response
}

export async function removeMapping(userName: string) {
  const url = `/analytics/replenishment/supplier/mapping`
  const response = await Api.delete(url, {user_name: userName}, {}, null)
  return response
}

export const getMappedUserList = (url: string, filters: any) => {
  return Api.get(`${url}`, filters).then((d: any) => {
    return d
  })
}

export const getClassification = (url: string, filters: any) => {
  return Api.get(`${url}`, filters).then((d: any) => {
    return d
  })
}

export const getSingleUserMappingDetails = (userName: string) => {
  const url = `/analytics/replenishment/sppliers?user=${userName}`
  return Api.get(`${url}`, {}).then((d: any) => {
    return d?.data?.data || {}
  })
}

export const multyProductAddTags = async (data: any) => {
  const url = `/analytics/replenishment/tags/products`
  const response = await Api.post(url, data, null, null)
  return response
}

export async function deleteTag(data: any) {
  const url = `/analytics/replenishment/tags`
  const response = await Api.delete(url, data, null, null)
  return response
}

export async function updateTag(data: any) {
  const url = `/analytics/replenishment/tags`
  const response = await Api.put(url, data, null, null)
  return response
}

export const getReplenishmentCsv = (filters: any) => {
  const url = '/analytics/replenishment/aggregate/csvdata'
  return Api.get(`${url}`, filters).then((d: any) => {
    return d
  })
}


export const getReplenishmentDashboardCsv = (filters: any) => {
  const url = '/analytics/replenishment/dashboard/csv'
  return Api.get(`${url}`, filters).then((d: any) => {
    return d
  })
}