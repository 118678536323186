import {formatPrice} from '../../../utils/common'

export const CUSTOMER_CHURN_COLUMNS = [
  {
    key: 'customer_name',
    label: 'Customer Name & Email',
    isSorted: true,
    headerStyle: 'min-w-250px',
  },
  {
    key: 'last_order_value',
    label: 'LOV (Last Order Value)',
    isSorted: true,
    headerStyle: 'min-w-120px',
  },
  {
    key: 'total_orders',
    label: 'Total Orders',
    isSorted: true,
    headerStyle: 'w-120px',
  },
  {
    key: 'expected_order_date',
    label: 'Expected Order Date',
    isSorted: true,
    headerStyle: 'min-w-120px',
  },
  {
    key: 'last_order_date',
    label: 'Last Order Date',
    isSorted: true,
    headerStyle: 'w-120px',
  },
]

export const parseCustomerChurnRow = (customer: any) => ({
  customerName: {
    name: customer.customer_name,
    email: customer.email,
    id: customer.customer_id,
    customerFirstNameLetter: customer.customer_name?.[0]?.toUpperCase() || '',
  },
  lastOrderValue: formatPrice(customer.last_order_value, false),
  totalOrders: customer.total_orders,
  expectedOrderDate: customer.expected_order_date,
  lastOrderDate: customer.last_order_date,
})
