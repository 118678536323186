interface InitialsProps {
  text: string
}

// Define color mapping based on alphabet groups
const colorMapping: Record<string, string> = {
  A: 'primary',
  B: 'primary',
  C: 'primary',
  D: 'primary',
  E: 'primary',
  F: 'success',
  G: 'success',
  H: 'success',
  I: 'success',
  J: 'success',
  K: 'info',
  L: 'info',
  M: 'info',
  N: 'info',
  O: 'info',
  P: 'danger',
  Q: 'danger',
  R: 'danger',
  S: 'danger',
  T: 'danger',
  U: 'primary',
  V: 'primary',
  W: 'primary',
  X: 'primary',
  Y: 'primary',
  Z: 'primary',
}

function Initials({text}: InitialsProps) {
  const firstChar = text.charAt(0).toUpperCase()
  const bg_color = colorMapping[firstChar] || 'secondary'

  return (
    <div className={`symbol-label fs-5 fw-semibold bg-light-${bg_color} text-${bg_color}`}>
      {text}
    </div>
  )
}

export default Initials
