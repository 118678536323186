/* eslint-disable */
import React, {useEffect, useRef, useCallback, useMemo, useState} from 'react'
import {Controller} from 'react-hook-form'
import {convertSelectedValueType, dataToSelectOptions} from '../../utils/common'
import {ReachSelectTypes} from '../reachSelect/ReachSelectTypes'
import Select, {components} from 'react-select'
import './reachAsyncSelect.scss'
import debounce from 'lodash/debounce'

function ReachSelect({
  id,
  name,
  value,
  control,
  label,
  className = '',
  selectClass = '',
  onChange,
  disabled = false,
  isRequired,
  options,
  formLabel,
  error,
  touched,
  placeholder,
  isNullable,
  defaultValue = '',
  selectRef,
  registerKey,
  onBlur,
  placeholderValue = '',
  isReadOnly = false,
  labelClass = '',
  labelKey = 'label',
  valueKey = 'value',
  valueType = 'string',
  isLoading = false,
  registerOptions,
  disabledKey,
  isFloatingMenu = true,
  disableDropdownIndicator = false,
  menuStyle,
  autoFocus,
  hasMoreOptions = false,
  onPageChange: onLoadMore,
  onSearch,
  pagination,
  isMultiSelect = false,
  isResetOptions = false,
  ...rest
}: ReachSelectTypes) {
  const internalSelectRef = useRef<any>(null)
  const [accumulatedOptions, setAccumulatedOptions] = useState<any[]>([])
  const [inputValue, setInputValue] = useState('')
  const hasMore = hasMoreOptions ? hasMoreOptions : pagination?.next_page !== null

  const formattedOptions =
    options && options?.length > 0
      ? labelKey && valueKey
        ? dataToSelectOptions(options, labelKey, valueKey, valueType, disabledKey, 'isDisabled')
        : dataToSelectOptions(options, 'label', 'value', valueType, disabledKey, 'isDisabled')
      : []

  useEffect(() => {
    if (formattedOptions) {
      setAccumulatedOptions((prevOptions) => {
        const optionsObject = Object.fromEntries(prevOptions.map((opt) => [opt.value, opt]))
        formattedOptions.forEach((opt) => {
          optionsObject[opt.value] = opt
        })
        return Object.values(optionsObject)
      })
    }
  }, [options])

  useEffect(() => {
    if (isResetOptions) {
      setAccumulatedOptions([])
    }
  }, [isResetOptions])

  useEffect(() => {
    if (inputValue) {
      setAccumulatedOptions(formattedOptions)
    }
  }, [inputValue])

  const optionsWithLoadingState = useMemo(() => {
    if (hasMore && isLoading && !inputValue) {
      return [
        ...accumulatedOptions,
        {
          label: (
            <div className='d-flex align-items-center gap-2'>
              <span className='spinner-border spinner-border-sm' role='status' />
              <span>Loading more...</span>
            </div>
          ),
          value: 'loading-more',
          isDisabled: true,
        },
      ]
    } else if (inputValue && isLoading) {
      return [
        ...accumulatedOptions,
        {
          label: (
            <div className='d-flex align-items-center gap-2'>
              <span className='spinner-border spinner-border-sm' role='status' />
              <span>Searching</span>
            </div>
          ),
          value: 'searching',
          isDisabled: true,
        },
      ]
    }
    return accumulatedOptions
  }, [accumulatedOptions, hasMore, isLoading, inputValue])

  const filterOption = (option: any, inputValue: string) => {
    if (option.value === 'searching') return true
    const label = option?.label?.toString()?.toLowerCase()
    return label?.includes(inputValue?.toString()?.toLowerCase())
  }

  const handleChange = (selectedOption: any, registerOnChange?: any) => {
    onSearch?.('')
    setInputValue('')
    const selectedValue = selectedOption
      ? convertSelectedValueType(accumulatedOptions, selectedOption.value)
      : placeholderValue
    if (onChange)
      onChange(
        selectedOption ? selectedOption : {label: placeholderValue, value: placeholderValue},
        selectedValue
      )
    if (registerOnChange && registerKey) registerOnChange(selectedValue)
  }

  useEffect(() => {
    if (autoFocus && internalSelectRef.current) {
      requestAnimationFrame(() => {
        internalSelectRef.current?.focus()
      })
    }
  }, [autoFocus])

  function mergeRefs<T>(...refs: React.Ref<T>[]) {
    return (instance: T | null) => {
      refs.forEach((ref) => {
        if (typeof ref === 'function') {
          ref(instance)
        } else if (ref && typeof ref === 'object') {
          ;(ref as React.MutableRefObject<T | null>).current = instance
        }
      })
    }
  }

  const handleMenuScrollToBottom = () => {
    if (hasMore && onLoadMore) {
      onLoadMore(pagination?.next_page)
    }
  }

  const debouncedSearch = useCallback(
    debounce((value: string) => {
      onSearch?.(value)
    }, 1000),
    [onSearch]
  )

  const handleInputChange = (inputValue: string, {action}: {action: string}) => {
    if (action === 'input-change') {
      setInputValue(inputValue)
      debouncedSearch(inputValue)
    }
    if (action === 'menu-close') {
      setInputValue('')
    }
  }

  const customStyles = {
    menu: (provided: any) => ({
      ...provided,
      ...(!isFloatingMenu && {position: 'relative'}),
      ...(!isFloatingMenu && {boxShadow: 'none !important'}),
      ...(!isFloatingMenu && {border: 'none !important'}),
      ...menuStyle,
    }),
    dropdownIndicator: (provided: any) => ({
      ...provided,
      display: disableDropdownIndicator ? 'none' : 'block',
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      padding: '8px 12px',
    }),
    control: (provided: any) => ({
      ...provided,
      cursor: 'pointer',
    }),
  }

  const customStylesForMultiSelect = {
    menu: (provided: any) => ({
      ...provided,
      zIndex: 9999,
    }),

    option: (provided: any, state: any) => ({
      ...provided,
      padding: '8px 12px',
      margin: '2px',
      display: 'flex',
      alignItems: 'center',
      backgroundColor: provided.backgroundColor,
      color: state.isSelected ? '#000' : provided.color,
      cursor: 'pointer',
    }),
    control: (provided: any) => ({
      ...provided,
      cursor: 'pointer',
    }),
  }

  const CustomOption = (props: any) => {
    return (
      <components.Option {...props}>
        <div className='d-flex'>
          <input
            type='checkbox'
            checked={props.isSelected}
            onChange={() => null}
            style={{marginRight: '8px'}}
          />
          <label>{props.label}</label>
        </div>
      </components.Option>
    )
  }

  const selectComponent = (
    <Select
      options={optionsWithLoadingState}
      placeholder={placeholder}
      isLoading={isLoading}
      onMenuScrollToBottom={handleMenuScrollToBottom}
      className={`${selectClass} ${error ? 'is-invalid border border-1 border-danger' : ''}`}
      id={id}
      {...(!isMultiSelect ? {} : {components: {Option: CustomOption}})}
      name={name}
      ref={mergeRefs(internalSelectRef, selectRef)}
      onChange={handleChange}
      onBlur={onBlur}
      {...(value ? {value: value} : {})}
      defaultValue={defaultValue}
      isDisabled={disabled || isReadOnly}
      isClearable={isNullable}
      classNamePrefix='react-select'
      onInputChange={handleInputChange}
      styles={isMultiSelect ? customStylesForMultiSelect : customStyles}
      filterOption={filterOption}
      {...rest}
      blurInputOnSelect={false}
      closeMenuOnSelect={!isMultiSelect}
      hideSelectedOptions={!isMultiSelect}
      isMulti={isMultiSelect}
    />
  )

  return (
    <div className={'position-relative ' + className}>
      {label && (
        <label
          className={`form-label ${labelClass} ${isRequired ? 'required' : ''}`}
          form-label={formLabel}
        >
          {label}
        </label>
      )}

      {control && registerKey ? (
        <Controller
          control={control}
          name={registerKey}
          defaultValue={defaultValue}
          rules={registerOptions}
          render={({field: {value: registerValue, onChange: registerOnChange}}) => {
            const defaultRegisteredValue = accumulatedOptions.find(
              (option: any) => option.value === registerValue
            )
            return React.cloneElement(selectComponent, {
              value: value ? value : defaultRegisteredValue ?? placeholderValue,
              defaultValue: defaultRegisteredValue,
              onChange: (selectedOption: any) => handleChange(selectedOption, registerOnChange),
            })
          }}
        />
      ) : (
        React.cloneElement(selectComponent, {
          value: value || undefined,
          defaultValue: defaultValue,
          onChange: handleChange,
        })
      )}
      {error && error?.message && (
        <div className='form-error invalid-feedback'>{error.message}</div>
      )}
    </div>
  )
}

export default ReachSelect
