import {useState, useContext, useEffect} from 'react'
import {Modal} from 'react-bootstrap'
import {useForm} from 'react-hook-form'
import useGetAllSuppliers from '../../hooks/useGetAllSuppliers'
import {UserSupplierMappingContext} from '../../context'
import {dataToFormValues, extractValues} from '../../utils'
import {getSingleUserMappingDetails} from '../../services'
import MultipleSelect from '../../../../components/MultiSelect'
import {dataToSelectList} from '../../../../utils/common'

function UpdateSupplierModal({show, onClose, user}: any) {
  const {onEditMapping} = useContext(UserSupplierMappingContext)

  const {
    handleSubmit,
    register,
    setValue,
    formState: {errors},
  } = useForm<any>({})
  const [data, setData] = useState<any>({})

  const {suppliers} = useGetAllSuppliers()

  const onSubmit = async (formData: any) => {
    const allValues = extractValues(formData['suppliers'])

    const reqObj: any = {
      user_name: data['name']['value'],
      suppliers: allValues,
      email_id: data['email'],
    }

    const res: any = await onEditMapping(reqObj)
    if (res) onClose()
  }

  useEffect(() => {
    const fetchUser = async () => {
      const userDetails = await getSingleUserMappingDetails(user)

      if (userDetails) {
        const formValues = dataToFormValues(userDetails)

        setData(formValues)
      }
    }

    // refill form data...
    if (user) {
      fetchUser()
    }
  }, [user, setValue])

  return (
    <Modal
      className='modal fade'
      id='kt_modal_create_supplier_mapping'
      data-backdrop='static'
      tabIndex={-1}
      role='dialog'
      show={show}
      dialogClassName='modal-l'
      aria-hidden='true'
      onHide={onClose}
    >
      <div className='modal-content'>
        <div className='modal-header'>
          <h4 className='modal-title'>{'Update Mapping'}</h4>

          <div className='btn btn-icon btn-sm btn-active-light-primary ms-2' onClick={onClose}>
            {/* <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-2x' /> */}
            <i className='bi bi-x fs-1 text-dark'></i>
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='modal-body'>
            <div className='form-label'>{'Select Suppliers'}</div>
            <MultipleSelect
              options={dataToSelectList(suppliers) || []}
              defaultValues={data?.['suppliers'] || []}
              handleSelected={(suppliers: any) => setValue('suppliers', suppliers)}
              {...register('suppliers', {required: true})}
            />
            {errors?.suppliers?.type === 'required' && (
              <span className='mt-1 form-error text-danger'>Supplier is required</span>
            )}
          </div>
          <div className='modal-footer'>
            <button
              type='button'
              className='btn btn-outline btn-active-light-primary'
              onClick={onClose}
            >
              Cancel
            </button>
            <button type='submit' className='btn btn-primary'>
              Update
            </button>
          </div>
        </form>
      </div>
    </Modal>
  )
}

export default UpdateSupplierModal
