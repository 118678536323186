const Tooltip = ({tooltipText, text}: {tooltipText: string; text: string}) => {
  return (
    <div className='app-default-date-cell position-relative'>
      <span className='app-default-date-area position-relative'>
        {text}
        <span className='popover app-default-date-tooltip fw-semibold lh-1 fs-8'>
          {tooltipText}
        </span>
      </span>
    </div>
  )
}

export default Tooltip
