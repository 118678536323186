import useApi from '../../../services/useApi'
import useFilters from '../../../hook/useFilters'

const useGetWebVisitors = () => {
  const Api = useApi()

  const initialFilters = {
    search: '',
    page: 1,
    limit: 30,
    sort_by: 'created_at/-1',
  }

  const {filters, onSearch, onSortingChange, onPageChange} = useFilters(initialFilters, {
    sortType: 'number',
  })

  const {data: response, isFetching} = Api.useGetQuery('/analytics/web-visitors', {
    queryId: 'web-visitors-list',
    filters: filters,
    isToast: false,
    isErrorPageRedirection: false,
  })

  return {
    visitors: response?.data || [],
    isLoading: isFetching,
    onSearch,
    onSortingChange,
    onPageChange,
    pagination: response?.meta?.pagination || {},
  }
}

export default useGetWebVisitors
