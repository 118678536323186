import {useContext, useEffect, useState} from 'react'
import LiquidationReportChildTable from './LiquidationReportChildTable'
import ProductReportOverlay from '../overlay/ProductReportOverlay'
import {LiquidationReportPageContext} from '../../context'
import {Link} from 'react-router-dom'
import {formatPrice} from '../../../../utils/common'
import defaultImage from '../../../../images/default-bc-product.png'

const LiquidationReportTableRow = ({
  row,
  isChild,
  checkBoxComponent,
}: any) => {
  const [showChildTable, setShowChildTable] = useState(false)
  const {updateLiquidationReport, hasProjectSettingsPermission} = useContext(
    LiquidationReportPageContext
  )
  const [updatedPrice, setUpdatedPrice] = useState(row.price_per_piece || '')
  const [originalPrice, setOriginalPrice] = useState(row.price_per_piece || '')
  const [isEditing, setIsEditing] = useState(false)

  useEffect(() => {
    setUpdatedPrice(row.price_per_piece || '')
    setOriginalPrice(row.price_per_piece || '')
  }, [row])

  const handlePriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value.trim()

    if (isNaN(Number(value))) return
    if (!/^\d*\.?\d{0,2}$/.test(value)) return
    if (parseFloat(value) < 0) value = ''
    if (value === '0') value = ''

    setUpdatedPrice(value)
  }

  const savePriceChange = () => {
    setIsEditing(false)

    if (updatedPrice === originalPrice) return // Prevent unnecessary API calls

    const newPrice = updatedPrice ? parseFloat(updatedPrice) : ''
    updateLiquidationReport({new_price: newPrice}, row.id)
    setOriginalPrice(updatedPrice) // Update original price after saving
  }

  return (
    <>
      <tr>
        {!isChild && checkBoxComponent && <td>{checkBoxComponent(row)}</td>}
        {isChild && <td className='ps-7'></td>}
        {row?.variants_info?.length > 0 && !isChild ? (
          <td className='text-center'>
            <span onClick={() => setShowChildTable((prev) => !prev)}>
              <i
                className={`bi ${showChildTable ? 'bi-dash-circle-fill' : 'bi-plus-circle-fill'}
                cursor-pointer fs-5`}
              ></i>
            </span>
          </td>
        ) : (
          <td></td>
        )}
        <td>
          {isChild ? (
            <>
              {row.product_name}
              <br />
              {row.variant_name && <span className='fw-bold'>{row.variant_name}</span>}
            </>
          ) : (
            <div className='d-flex align-items-center py-2'>
              <div className='symbol symbol-40px me-3 border'>
                <img src={row.thumbnail_url || defaultImage} alt='productimage' />
              </div>
              <Link
                to={`/analytics/sold-products/order-history/${row.id}`}
                target='_blank'
                className='text-dark'
              >
                {row.name}
              </Link>
            </div>
          )}
        </td>
        <td>
          {isChild ? (
            row.sku || '-'
          ) : (
            <Link
              to={`/analytics/sold-products/order-history/${row.id}`}
              target='_blank'
              className='text-dark'
            >
              {row.sku || '-'}
            </Link>
          )}
        </td>
        <td className='text-center'>{formatPrice(row.calculated_price, false)}</td>
        {isChild ? (
          <td></td>
        ) : (
          <td className='text-center'>{!isChild ? row.variant_count || '-' : '-'}</td>
        )}
        <td className='text-center'>{row.inventory_level || '-'}</td>
        {isChild ? (
          <td className='text-center'>{row.blocked_qty || '-'}</td>
        ) : (
          <td className='text-center'>{row.total_blocked_qty || '-'}</td>
        )}
        <td className='text-center'>{row.pack_count || '-'}</td>
        <td className='text-center'>{row.piece_count || '-'}</td>
        {/* Price Editable Field */}
        <td className='text-center'>
          {!isChild && isEditing && hasProjectSettingsPermission ? (
            <input
              type='text'
              className='form-control w-75px text-center d-inline-block'
              value={updatedPrice}
              onChange={handlePriceChange}
              onBlur={savePriceChange}
              autoFocus
            />
          ) : isChild ? (
            <span>{updatedPrice ? formatPrice(updatedPrice, false) : '-'}</span>
          ) : (
            <span
              className='form-control w-75px text-center d-inline-block cursor-pointer'
              onClick={() => setIsEditing(true)}
            >
              {updatedPrice || '-'}
            </span>
          )}
        </td>
        {!isChild && (
          <td>
            <ProductReportOverlay id={row?.id} />
          </td>
        )}
      </tr>

      {showChildTable && row?.variants_info?.length > 0 && (
        <tr>
          <td colSpan={11}>
            <LiquidationReportChildTable childData={row.variants_info} isChild={true} />
          </td>
        </tr>
      )}
    </>
  )
}

export default LiquidationReportTableRow
