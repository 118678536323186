import {useEffect} from 'react'
import useFilters from '../../../hook/useFilters'
import { useLocation, useNavigate } from 'react-router-dom'
import useApi from '../../../services/useApi'

export default function useGetUserList(fetchAll: boolean) {
  const Api = useApi()
  const location = useLocation()
  const navigate = useNavigate()
  const searchParams = new URLSearchParams(location.search)
  const page = searchParams.get('page')
  const {filters, setSingleFilter, setMultipleFilters} = useFilters({
    limit: fetchAll ? 250 : 20,
    page: page || 1,
    // filterBy: 'name',
    filter: '',
    sort_by: 'created_at',
    sort_order: 'desc',
  })

  const {
    data: response,
    refetch,
    isFetching,
  } = Api.useGetQuery(`/analytics/replenishment/users/dropdown`,  {
    queryId:'get-users-list',
    filters:filters
  })

  useEffect(() => {
    refetch()
  }, [filters, refetch])

  const onSearch = (searchValue: string) => {
    setMultipleFilters({
      filter: searchValue
    })
  }

  const onPageChange = (page_number: string | number | void) => {
    setSingleFilter('page', page_number)
  }

  const onSortingChange = (key: string, value: string) => {
    setMultipleFilters({
      sort_by: key,
      sort_order: value,
    })
    navigate(`${location.pathname}`)
  }

  return {
    users: response?.['data'] || [],
    refetch,
    isFetchingUsers: isFetching,
    onSearch,
    pagination: response?.['meta']?.['pagination'] || [],
    onPageChange,
    onSortingChange,
    filters
  }
}
