import { Pie } from 'react-chartjs-2';

interface PieChartProps {
  options: object ;
  data: any;
}

const PieChart = (props: PieChartProps) => {
  const { data, options } = props;
  return (
    <div>
      <Pie data={data} options={options} />
    </div>
  );
};

export default PieChart;
