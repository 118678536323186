import {KTCard} from '../../../_metronic/helpers'
import WebVisitorsHeader from '../sections/header/WebVisitorsHeader'
import WebVisitorsTable from '../sections/table/WebVisitorsTable'
import useGetWebVisitors from '../hooks/useGetWebVisitors'
import {WebVisitorsContext} from '../context'
import { Pagination } from '../../../utils/pagination'

function WebVisitors() {
  const {visitors, isLoading, onSearch, onSortingChange, onPageChange, pagination} =
    useGetWebVisitors()

  const contextValue = {
    visitors,
    isLoading,
    onSearch,
    onSortingChange,
    onPageChange,
  }

  return (
    <KTCard>
      <WebVisitorsContext.Provider value={contextValue}>
        <WebVisitorsHeader />
        <WebVisitorsTable />
        <Pagination pagination={pagination} onPageChange={onPageChange} />
      </WebVisitorsContext.Provider>
    </KTCard>
  )
}

export default WebVisitors
