import {Pagination} from '../../../utils/pagination'
import {ClassificationContext} from '../context'
import ClassificationHeader from '../sections/headers/ClassificationHeader'
import ClassificationTable from '../sections/table/ClassificationTable'
import useGetClassificationListing from '../hooks/useGetClassificationListing'
import {useState} from 'react'
import { useGetUniqueClassifications } from '../../priceList/hooks/useGetUniqueClassifications'

const Classification = () => {
  const {Classifications} = useGetUniqueClassifications()
  const [checkedRows, setCheckedRows] = useState<any>([])

  const {
    ClassificationData,
    isLoading,
    filters,
    onSearch,
    onPageChange,
    onSortingChange,
    pagination,
    isOperationLoading,
    onApprove,
    onReject,
    onClassificationChange,
    onClassificationFilterChange,
  } = useGetClassificationListing()

  const contextValue = {
    ClassificationData,
    isLoading,
    filters,
    pagination,
    onSearch,
    onSortingChange,
    isOperationLoading,
    onApprove,
    onReject,
    Classifications,
    onClassificationChange,
    onClassificationFilterChange,
    checkedRows,
    setCheckedRows,
  }

  return (
    <ClassificationContext.Provider value={contextValue}>
      <ClassificationHeader />
      <ClassificationTable />
      <Pagination pagination={pagination} onPageChange={onPageChange} />
    </ClassificationContext.Provider>
  )
}

export default Classification
