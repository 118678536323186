import {useContext, useState} from 'react'
import OverlayComponent from '../../../../_metronic/layout/components/Popover'
import {TagsDropdown} from '../dropdown/TagsDropdown'
import UpdateTagModal from './UpdateTagModal'
import {TagsManagementContext} from '../../context'
import {ConfirmationModal} from '../../../../components/ConfirmationModal'

const OverlayModal = ({row}: any) => {
  const [openModal, setOpenModal] = useState(false)
  const [modal, setModal] = useState(false)
  const {isLoading, isOperationLoading, onDeleteTag, setCheckedRows} =
    useContext(TagsManagementContext)
  const handleModalOpen = () => {
    setOpenModal(true)
    document.body.click()
  }
  const onEdit = () => {
    setModal(true)
    document.body.click()
  }
  const onClose = () => {
    setOpenModal(false)
    setModal(false)
  }

  const handleDelete = (payload: any) => {
    setCheckedRows([])
    onDeleteTag(payload)
  }
  return (
    <>
      <OverlayComponent
        btnIcon={<i className='las la-ellipsis-h fs-2x'></i>}
        children={<TagsDropdown toggleModal={handleModalOpen} onEdit={onEdit} />}
      />
      <ConfirmationModal
        uniqueID={row?.id}
        show={openModal}
        onClose={onClose}
        actionName='Yes, Delete'
        body={
          'By deleting this tag, we will also remove it from all the products to which it is assigned. Are you sure you want to delete this tag?'
        }
        isDataLoading={isLoading}
        isOperationLoading={isOperationLoading}
        onAction={(tagId: any) => handleDelete({tagId: [tagId.toString()]})}
      />

      {modal && (
        <UpdateTagModal show={modal} onClose={onClose} tagId={row?.id} oldName={row?.tag} />
      )}
    </>
  )
}

export default OverlayModal
