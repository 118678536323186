import useApi from '../../services/useApi'
import useFilters from '../useFilters'
import {useAppSelector} from '../../redux/useTypedSelector'
import {useInfiniteQuery, useQueryClient} from 'react-query'

export const useGetNotification = () => {
  const Api = useApi()
  const {isLoading} = useAppSelector((state) => state.auth)
  const queryClient = useQueryClient();
  const {user} = useAppSelector((state) => state.auth)

  const initialFilters = {
    page: 1,
    limit: 40,
    notification_filter: user?.role_id !== '66f4f1aacb3b3b19fd774f98' ? "Bulk Order" : '',
  }

  const {filters, setMultipleFilters} = useFilters(initialFilters)

  // Fetch Notifications
  const getNotifications = async ({pageParam = 1}) => {
    const response = await Api.get(`/notifications`, {
      filters: {...filters, page: pageParam},
      isToast: false,
      isErrorPageRedirection: false,
    })
    return response
  }

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isLoading: Loading,
    refetch,
  } = useInfiniteQuery(['product-notifications-listing', filters], getNotifications, {
    enabled: !isLoading,
    getNextPageParam: (lastPage) => lastPage?.meta?.next_page || undefined,
  })

  const onLoadMore = () => {
    if (hasNextPage) fetchNextPage()
  }

  const onChangeNotificationType = (type: string) => {
    queryClient.removeQueries(['product-notifications-listing']); 
    setMultipleFilters({
      notification_filter: type,
      page: 1,
    });
  };
  
  const onRefetch = () => {
    queryClient.removeQueries(['product-notifications-listing']); 
    setMultipleFilters({
      notification_filter: user?.role_id !== '66f4f1aacb3b3b19fd774f98' ? "Bulk Order" : '',
      page: 1,
    });
    refetch();
  };

  return {
    Notifications: data?.pages?.flatMap((pageData) => pageData?.data) || [],
    pagination: data?.pages?.flatMap((pageData) => pageData?.meta)?.[0] ?? {},
    onLoadMore,
    currentPage: filters.page,
    loading: isFetching || Loading,
    onChangeNotificationType,
    refetch: onRefetch,
  }
}
