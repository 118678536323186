import {createContext} from 'react'

export const ProductsListPageContext = createContext({
  bulkOrdersProductsList: {},
  onSearch: (searchValue: string) => {},
  onPageChange: (e: string) => {},
  isLoadingBulkOrdersProductsList: true,
  refetchBulkOrderProductDetails: () => {},
  onClickSorting: (sortBy: string, sortDirection: string) => {},
  filters: {},

  createBulkOrder: (bulkOrderDetails: {}) => {},
  updateBulkOrderProductDetails: (bulkOrderDetails: {}, bop_id: string) => {},
  isLoadingBulkOrderOperation: true,

  handleLockPoCheckbox: (value: any) => {},
  isArchived: false,

  poDetails: {},
  isLoadingPoDetails: false,
  refetchPoDetails: () => {},
  setProductId: (value: string) => {},
  isLoadingPendingPoDetails: false,
  pendingPoDetails: {},
  setId: (value: string) => {},
})

export const ProductInfoPageContext = createContext({
  productDetails: [],
  isLoadingProductDetails: true,
  refetchProductDetails: () => {},
  filters: {},
  errorMessage: '',

  register: undefined as any,
  unregister: undefined as any,
  control: undefined as any,
  setValue: undefined as any,
  watch: undefined as any,
  errors: {},
  onSearch: (searchValue: string) => {},

  createBulkOrder: (bulkOrderDetails: {}) => {},
  updateBulkOrderProductDetails: (bulkOrderDetails: {}, bop_id: string) => {},
  updatePreOrderProductDetails: (bulkOrderDetails: {}, bop_id: string) => {},
  isLoadingBulkOrderOperation: true,

  bulkOrderProductDetails: [],
  isLoadingBulkOrderProductDetails: true,
  refetchBulkOrderProductDetails: () => {},

  isAvailable: false,
  setIsAvailable: (value: boolean) => {},
  isPreOrderProduct: false,
  setIsPreOrderProduct: (value: boolean) => {},
  isCreateProductScreen: true,

  boBrandsDropdownList: [],
  isLoadingBoBrandsDropdownList: false,
  refetchBoBrandsDropdownList: () => {},
})

export const ProductDistributionPageContext = createContext({
  productDistributionDetails: [],
  isLoadingProductDistributionDetails: true,
  refetchProductDistributionDetails: () => {},

  createProductDistribution: (payload: {}) => {},
  updateProductDistribution: (distribution_id: string, payload: {}) => {},
  updateLockedPoDistribution: (bop_id: string, patchPayload: {}) => {},
  isLoadingBulkOrderOperation: false,

  register: undefined as any,
  unregister: undefined as any,
  control: undefined as any,
  setValue: undefined as any,
  errors: {},

  isChecked: false,
  setIsChecked: (value: boolean) => {},
  isPoLocked: false,
  setIsPoLocked: (value: boolean) => {},
  isDistributionValid: true,
  setIsDistributionValid: (value: boolean) => {},
})

export const ProductDistributionLogPageContext = createContext({
  productDistributionLogsDetails: [],
  isLoadingProductDistributionLogsDetails: true,
  refetchProductDistributionLogsDetails: () => {},
})

export const BcOrdersHistoryPageContext = createContext({
  bcOrdersHistoryList: [],
  poCustomers: [] as any,
  isLoadingBcOrdersHistoryList: true,
  refetchBcOrdersHistoryList: () => {},
  handleBcOrderHistoryStatus: (selectedOption: string) => {},
  handleBcOrderHistoryFilter: (selectedOption: string) => {},
})

export const POsHistoryPageContext = createContext({
  poHistoryList: [],
  poCustomers: [] as any,
  poCustomerReps: [] as any,
  filters: {} as any,
  onSortingChange: (sortBy: string, sortDirection: string) => {},
  isLoadingPOsHistoryList: true,
  refetchPOsHistoryList: () => {},
  handleBcOrderHistoryStatus: (selectedOption: string) => {},
  handleBcOrderHistoryFilter: (selectedOption: string) => {},
  handleBcOrderHistoryRepFilter: (selectedOption: string) => {},
})

export const CustomerTrackingPageContext = createContext({
  customerTrackingList: [],
  filters: {} as any,
  onSortingChange: (sortBy: string, sortDirection: string) => {},
  isLoading: true,
  refetchCustomerTrackingList: () => {},
  handleCustomerTrackingRepFilter: (selectedOption: string) => {},
  handleCustomerTrackingTagFilter: (selectedOption: string) => {},
  handleCustomerTrackingStateFilter: (selectedOption: string) => {},
  Representatives: [],
  customerTags: [],
  onSearch: (searchValue: string) => {},
  isLoadingState: false,
  states: [] as any,
  isFetchingCsvData: false,
  onDownloadCsv: (filters: any) => {},
})
