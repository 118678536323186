import {KTCard} from '../../../_metronic/helpers'
import {KTSVG} from '../../../_metronic/helpers'
import BarChart from '../../../components/charts/BarChart'
import PieChart from '../../../components/charts/PieChart'
import {
  BarChartData,
  PieCharData,
  formatPieChartData,
  getBarChartOptions,
  mapCustomerGroups,
  pieChartOptions,
  transformToBarChartData,
} from '../utils'
import CustomerGroupTable from '../sections/table/CustomerGroupTable'
import useGetCustomersGroups from '../hooks/useGetCustomersGroups'
import PredefinedDateRange from '../../../components/DateRangePicker/PreDefinedDateRange'
import {useLocation} from 'react-router-dom'
import {ProfitabilityContext} from '../context'
import useGetCustomers from '../hooks/useGetCustomers'
import useGetProfitability from '../hooks/useGetProfitability'
import {useState} from 'react'
import {formatPrice} from '../../../utils/common'
import Customers from '../pages/Customers'
import SectionLoading from '../../loading/section-loading'
import Tooltip from '../../../components/Tooltip/Tooltip'

function Profitability() {
  const location = useLocation()
  const isFullView = location.pathname === '/analytics/profitability/customers'
  const [dateRangeLable, setDateRangeLable] = useState<string>('Current Year')

  const {
    profitabilityData,
    isLoading,
    onSearch,
    onSortingChange,
    onPageChange,
    pagination,
    onDateChangeCustomers,
  } = useGetCustomers()

  const {
    CustomersGroupsData,
    onDateChangeCustomersGroups,
    isLoading: isCustomersGroupsLoading,
  } = useGetCustomersGroups()

  const {
    profitabilitySummaryData,
    onDateChangeProfitabilitySummary,
    isLoading: isProfitabilitySummaryLoading,
  } = useGetProfitability()

  const {customer_type_metrics, top_20_percent, total_metrics} = profitabilitySummaryData

  const barChartDataValues = transformToBarChartData(CustomersGroupsData)
  const mappedCustomerGroups = mapCustomerGroups(CustomersGroupsData)

  const contextValue = {
    profitabilityData,
    isLoading,
    onSearch,
    onSortingChange,
    onPageChange,
    pagination,
    onDateChangeCustomers,
    CustomersGroupsData,
    isCustomersGroupsLoading,
    profitabilitySummaryData,
    isProfitabilitySummaryLoading,
  }

  const pieChartDataValues = formatPieChartData(total_metrics || {})

  const handleDateChange = (startDate: any, endDate: any) => {
    onDateChangeCustomers(startDate, endDate)
    onDateChangeCustomersGroups(startDate, endDate)
    onDateChangeProfitabilitySummary(startDate, endDate)
  }

  return (
    <div className='layout-profitability-inner'>
      <KTCard>
        <ProfitabilityContext.Provider value={contextValue}>
          {!isFullView && (
            <>
              <div className='mb-10'>
                <div className='d-flex justify-content-between'>
                  <h3>
                    All Customer-Profit reports are generated from orders with a Completed status
                    only.
                  </h3>
                  <div className='w-250px'>
                    <PredefinedDateRange
                      cb={handleDateChange}
                      getLbl={setDateRangeLable}
                      defaultValue={'Current Year'}
                      includeCurrantDay={true}
                      minPastDaysAllowed={730}
                    />
                  </div>
                </div>
              </div>

              <div className='row g-xl-custom-7 mb-10'>
                <div className='col-xl-4 mt-0'>
                  <div className='card border card-flush h-xl-100'>
                    <div className='card-body p-7'>
                      <div className='mb-10'>
                        <h3 className='mb-0'>Top Customer Group</h3>
                        <span className='fs-6 fw-semibold text-gray-500'>
                          {CustomersGroupsData?.[0]?.customer_group_name}
                        </span>
                        {isCustomersGroupsLoading && <SectionLoading />}
                      </div>
                      <BarChart
                        data={BarChartData(barChartDataValues)}
                        options={getBarChartOptions(mappedCustomerGroups)}
                        height={300}
                      />
                    </div>
                  </div>
                </div>

                {/* Profitability Overview */}
                <div className='col-xl-4 mt-0'>
                  <div className='card border card-flush h-xl-100'>
                    <div className='card-body p-7'>
                      <div className='profibility-chart-wrapper w-100'>
                        <div className='w-100'>
                          <div className='mb-5'>
                            <h3 className='mb-0'>Profitability</h3>
                            <span className='fs-6 fw-semibold text-gray-500'>{dateRangeLable}</span>
                            {isProfitabilitySummaryLoading && <SectionLoading />}
                          </div>
                        </div>

                        <div className='w-100 d-flex justify-content-center'>
                          <div className='col-xxl-7 col-xl-12 col-lg-12 position-relative'>
                            <PieChart
                              data={PieCharData(pieChartDataValues)}
                              options={pieChartOptions}
                            />
                            <div className='position-absolute top-50 start-50 translate-middle text-center'>
                              <p className='fs-1 fw-bold mb-0'>
                                <Tooltip
                                  text={total_metrics?.revenue_label || 0}
                                  tooltipText={formatPrice(total_metrics?.revenue, false)}
                                />
                              </p>
                              <span className='fw-semibold text-gray-500'>Total Revenue</span>
                            </div>
                          </div>
                        </div>
                        <div className='w-100 mt-5'>
                          <div className='row'>
                            <div className='col-6'>
                              <h3 className='mb-0 fs-4'>Cost</h3>
                              <span className='fs-6 fw-semibold text-gray-500'>
                                {formatPrice(total_metrics?.cost, false)}
                              </span>
                            </div>
                            <div className='col-6 text-end'>
                              <h3 className='mb-0 fs-4'>Profit</h3>
                              <span className='fs-6 fw-semibold text-gray-500'>
                                {formatPrice(total_metrics?.profit, false)}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Revenue Stats */}
                <div className='col-xl-4 mt-0'>
                  <div className='row g-xl-custom-7'>
                    {/* Top 20% Revenue */}
                    <div className='col-xl-6 mb-5'>
                      <div className='card border card-flush h-xl-100'>
                        <div className='card-body p-7'>
                          <div className='symbol symbol-50px w-40px h-40px bg-light-primary d-flex align-items-center justify-content-center mb-5'>
                            <i className='icon-24'>
                              <KTSVG path='/media/ad-theme-icons/blue-doller-icon.svg' />
                            </i>
                          </div>
                          <div className='mb-5'>
                            <span className='fs-6 fw-semibold'>Top 20% Revenue</span>
                            <h3 className='mb-0 fw-bolder fs-2'>
                              {top_20_percent?.revenue_percentage || 0}%
                            </h3>
                          </div>
                          <span className='fs-6 fw-semibold text-gray-500'>
                            {top_20_percent?.customer_count} Customers
                          </span>
                        </div>
                      </div>
                    </div>

                    {/* Chain Store & Retailer */}
                    <div className='col-xl-6 mb-5'>
                      <div className='card border card-flush h-xl-100'>
                        <div className='card-body p-7'>
                          <div className='symbol symbol-50px w-40px h-40px bg-light-primary d-flex align-items-center justify-content-center mb-5'>
                            <i className='icon-24'>
                              <KTSVG path='/media/ad-theme-icons/blue-doller-icon.svg' />
                            </i>
                          </div>
                          <div className='mb-5'>
                            <span className='fs-6 fw-semibold'>Top 20% Revenue</span>
                            <h3 className='mb-0 fw-bolder fs-2'>
                              <Tooltip
                                tooltipText={formatPrice(top_20_percent?.revenue, false)}
                                text={`$${top_20_percent?.revenue_label || 0}`}
                              />
                            </h3>
                          </div>
                          <span className='fs-6 fw-semibold text-gray-500'>
                            {top_20_percent?.orders} Orders
                          </span>
                        </div>
                      </div>
                    </div>

                    {/* Revenue Stats */}
                    <div className='col-xl-6 mb-5'>
                      <div className='card border card-flush h-xl-100'>
                        <div className='card-body p-7'>
                          <div className='symbol symbol-50px w-40px h-40px bg-light-primary d-flex align-items-center justify-content-center mb-5'>
                            <i className='icon-24'>
                              <KTSVG path='/media/ad-theme-icons/blue-doller-icon.svg' />
                            </i>
                          </div>
                          <div className='mb-5'>
                            <span className='fs-6 fw-semibold'>
                              {customer_type_metrics?.other?.revenue_percentage || 0}%
                            </span>
                            <h3 className='mb-0 fw-bolder fs-2'>
                              <Tooltip
                                tooltipText={formatPrice(
                                  customer_type_metrics?.other?.revenue,
                                  false
                                )}
                                text={`$${customer_type_metrics?.other?.revenue_label || 0}`}
                              />
                            </h3>
                          </div>
                          <span className='fs-6 fw-semibold text-gray-500'>
                            Chain Store & Retailer
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className='col-xl-6 mb-5'>
                      <div className='card border card-flush h-xl-100'>
                        <div className='card-body p-7'>
                          <div className='symbol symbol-50px w-40px h-40px bg-light-primary d-flex align-items-center justify-content-center mb-5'>
                            <i className='icon-24'>
                              <KTSVG path='/media/ad-theme-icons/blue-doller-icon.svg' />
                            </i>
                          </div>
                          <div className='mb-5'>
                            <span className='fs-6 fw-semibold'>
                              {customer_type_metrics?.distributor?.revenue_percentage || 0}%
                            </span>
                            <h3 className='mb-0 fw-bolder fs-2'>
                              <Tooltip
                                tooltipText={formatPrice(
                                  customer_type_metrics?.distributor?.revenue,
                                  false
                                )}
                                text={`$${customer_type_metrics?.distributor?.revenue_label || 0}`}
                              />
                            </h3>
                          </div>
                          <span className='fs-6 fw-semibold text-gray-500'>Distributor</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Customer Group Table */}
              <div className='mb-10'>
                <CustomerGroupTable />
              </div>
            </>
          )}

          {/* Customers Table */}
          <div className='mb-10'>
            <Customers isFullView={isFullView} />
          </div>
        </ProfitabilityContext.Provider>
      </KTCard>
    </div>
  )
}

export default Profitability
