import {useContext} from 'react'
import {KTSVG} from '../../../_metronic/helpers'
import useDrawerToggle from '../hooks/useDrawerToggle'
import ColumnsTable from '../sections/ColumnsTable'
import {formatForDndData, removeColumn} from '../utils'
import {SideMenuContext} from '../context'

const SidebarMenu = () => {
  const {isDrawerOpen, closeDrawer} = useDrawerToggle()

  const {
    type,
    selectedColumns,
    updatedDefaultColumn,
    defaultColumns,
    updateGlobalDefaultColumnsTableSorting,
    refetch,
    refetchGlobalDefaultColumnsList,
    isLoadingGlobalDefaultColumnOperation,
    setUpdatedDefaultColumn,
    setSelectedColumns,
  } = useContext(SideMenuContext)

  const handleSave = async () => {
    const savedColumnOrder = JSON.parse(sessionStorage.getItem('sortedColumnIds') || '[]')
    const columnsMap = Object.fromEntries(
      selectedColumns.map((col: any) => [col.key, col.title.toUpperCase()])
    )

    const columnsPayload = savedColumnOrder
      .filter((columnId: string) => {
        const column: any = updatedDefaultColumn.find((col: any) => col.value === columnId)
        return column && (column.default === true || columnsMap[columnId])
      })
      .map((columnId: string) => ({[columnId]: columnsMap[columnId]}))

    if (defaultColumns.some((column: any) => 'action' in column)) {
      columnsPayload.push({action: 'ACTION'})
    }
    const columnsString = JSON.stringify(columnsPayload, null, 2);
    document.getElementById('kt_drawer_column_setting_close')?.click()
    const res: any = await updateGlobalDefaultColumnsTableSorting({
      columns: `${columnsString}`,
      type,
    })

    if (res?.status === 200) {
      refetch()
      refetchGlobalDefaultColumnsList()
      closeDrawer()
    }
  }

  const handleReset = () => {
    const updatedColumn = removeColumn(defaultColumns, 'action')
    const formattedColumns = formatForDndData(updatedColumn)
    setSelectedColumns(formattedColumns.map((column) => ({key: column.value, title: column.label})))
    setUpdatedDefaultColumn(formattedColumns)
    sessionStorage.setItem(
      'sortedColumnIds',
      JSON.stringify(formattedColumns.map((item) => item.value))
    )
  }

  const handleCancel = () => {
    closeDrawer()
  }

  return (
    <div
      id='kt_drawer_column_setting'
      data-kt-drawer='true'
      data-kt-drawer-activate='true'
      data-kt-drawer-overlay='true'
      data-kt-drawer-direction='end'
      data-kt-drawer-toggle='#kt_drawer_column_setting_toggle'
      data-kt-drawer-close='#kt_drawer_column_setting_close'
      className={`bg-body drawer drawer-end ${
        isDrawerOpen ? 'drawer-on' : ''
      } min-w-100px app-drawer-nav-section`}
    >
      <div className='h-100 px-8'>
        <div className='py-5 border-bottom mb-5 d-flex justify-content-between align-items-center'>
          <h4 className='modal-title fw-bolder'>Settings</h4>
          <div id='kt_drawer_column_setting_close' onClick={handleCancel}>
            <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
          </div>
        </div>

        <div className='app-drawer-nav-body' id='kt_drawer_chat_messenger_body'>
          <div className='d-flex justify-content-between mb-5 align-items-center'>
            <h4 className='mb-0'>Columns</h4>
            <button className='btn btn-outline' onClick={handleReset}>
              Reset
            </button>
          </div>
          <ColumnsTable />
        </div>

        <div className='d-flex align-items-center justify-content-end border-top mt-5 py-5'>
          <button
            type='button'
            className='btn btn-outline'
            id='kt_drawer_column_setting_close'
            onClick={handleCancel}
          >
            Cancel
          </button>
          <button type='button' className='btn btn-primary ms-3' onClick={handleSave}>
            {isLoadingGlobalDefaultColumnOperation ? (
              <span className='indicator-progress' style={{display: 'block'}}>
                Saving...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            ) : (
              <span className='indicator-label'>Save</span>
            )}
          </button>
        </div>
      </div>
    </div>
  )
}

export default SidebarMenu
