import { formatTime } from '../../utils/common'
import {convertUTCToLocal, convertUTCToLocalDynamicDate, formatDate} from '../../utils/date'

const Date = ({date, isDynamicFormat = false, isTime = false, isDate = false}: any) => {
  return (
    <div className='app-default-date-cell position-relative'>
      {isTime ? (
        <span className='app-default-date-area position-relative'>
          {convertUTCToLocalDynamicDate(date, isDate)}
          <span className='popover app-default-date-tooltip fw-semibold lh-1 fs-8'>
            {isDynamicFormat ? convertUTCToLocal(date, false, false) : formatTime(date)}
          </span>
        </span>
      ) : (
        <span className='app-default-date-area position-relative'>
          {isDynamicFormat ? convertUTCToLocal(date, true, true) : formatDate(date, true)}
          <span className='popover app-default-date-tooltip fw-semibold lh-1 fs-8'>
            {isDynamicFormat ? convertUTCToLocal(date, false, false) : formatDate(date, false)}
          </span>
        </span>
      )}
    </div>
  )
}

export default Date
