import useApi from '../../../services/useApi'
import useFilters from '../../../hook/useFilters'
import moment from 'moment'
const useGetCustomers = () => {
  const Api = useApi()

  const initialFilters = {
    page: 1,
    limit: 30,
    sort_by: 'total_profit/-1',
    start_date: moment().startOf('year').format('YYYY-MM-DD'), // Start of the current year
    end_date: moment().format('YYYY-MM-DD'), // Today's date
  }

  const {filters, onSearch, onSortingChange, onPageChange, onDateChange} = useFilters(
    initialFilters,
    {
      sortType: 'number',
    }
  )

  const {data: response, isFetching} = Api.useGetQuery('/analytics/profitability/customers', {
    queryId: 'profitability-list',
    filters: filters,
    isToast: false,
    isErrorPageRedirection: false,
  })

  return {
    profitabilityData: response?.data || [],
    isLoading: isFetching,
    onSearch,
    onSortingChange,
    onPageChange,
    pagination: response?.meta?.pagination || {},
    onDateChangeCustomers: onDateChange,
  }
}

export default useGetCustomers
