/* eslint-disable react/jsx-no-target-blank */
import {useIntl} from 'react-intl'
import {AsideMenuItem} from './AsideMenuItem'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import isEmpty from 'lodash/isEmpty'
import {addStaticMenuItems} from './addStaticMenuItems'
import usePermission from '../../../../hook/usePermission'

const icon_base_url = '/media/ad-theme-icons/'

type Props = {
  isToggleActive: boolean
}

const staticMenus: any = [
  {
    path: ['analytics'],
    staticObj: {
      label: 'Profitability',
      icon: 'bi bi-bell',
      fontIcon: 'bi-bell',
      route: '/analytics/profitability',
    },
    position: 1,
  },
]

export function AsideMenuMain({isToggleActive}: Props) {
  const intl = useIntl()
  const {menu} = usePermission()
  const updatedMenuData = addStaticMenuItems(menu, staticMenus)

  const checkKeysInItem = (item: any): boolean => {
    const keysToCheck = ['utility', 'analytics', 'settings', 'projects']
    const hasKeyInItem = keysToCheck.some((key) => key === item.name)
    const hasKeyInChildren = item.children
      ? Object.values(item.children).some((child) => checkKeysInItem(child))
      : false
    return hasKeyInItem || hasKeyInChildren
  }

  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        icon={`${icon_base_url}dashboard.svg`}
      />

      {Object.entries(updatedMenuData).map(([, item]: any) => {
        if (!isEmpty(item.children)) {
          const firstChildKey = Object.keys(item.children)[0]
          const firstChild = item.children[firstChildKey]
          return (
            <AsideMenuItemWithSub
              to={item.route}
              title={item.label}
              icon={`${icon_base_url}${item.icon}`}
              key={item.label}
              isToggleActive={isToggleActive}
              firstChildRoute={firstChild?.route}
              isScroll={checkKeysInItem(item)}
            >
              {Object.entries(item.children).map(([, child_item]: any) => {
                if (child_item.label === 'Components') {
                  return null
                } else {
                  if (!isEmpty(child_item.children)) {
                    return (
                      <AsideMenuItemWithSub
                        to={child_item.route}
                        title={child_item.label}
                        key={child_item.label}
                        isToggleActive={isToggleActive}
                        firstChildRoute={firstChild?.route}
                      >
                        {Object.entries(child_item.children).map(([, second_child_item]: any) => {
                          if (
                            second_child_item.label === 'Components' ||
                            second_child_item.label === 'API Token' ||
                            second_child_item.label === 'App Builder' ||
                            second_child_item.label === 'Custom Query'
                          ) {
                            return null
                          } else {
                            if (!isEmpty(second_child_item.children)) {
                              return (
                                <AsideMenuItemWithSub
                                  to={second_child_item.route}
                                  title={second_child_item.label}
                                  key={second_child_item.label}
                                  isToggleActive={isToggleActive}
                                  firstChildRoute={firstChild?.route}
                                >
                                  {Object.entries(second_child_item.children).map(
                                    ([, third_child_item]: any) => {
                                      if (third_child_item.label === 'Components') {
                                        return null
                                      } else {
                                        return (
                                          <div key={third_child_item.label}>
                                            <AsideMenuItem
                                              to={`${child_item.route}${second_child_item.route}${third_child_item.route}`}
                                              title={third_child_item.label}
                                            />
                                          </div>
                                        )
                                      }
                                    }
                                  )}
                                </AsideMenuItemWithSub>
                              )
                            } else {
                              return (
                                <div key={second_child_item.label}>
                                  <AsideMenuItem
                                    to={`${child_item.route}${second_child_item.route}`}
                                    title={second_child_item.label}
                                  />
                                </div>
                              )
                            }
                          }
                        })}
                      </AsideMenuItemWithSub>
                    )
                  } else {
                    return (
                      <AsideMenuItem
                        to={child_item.route}
                        title={child_item.label}
                        key={child_item.label}
                      />
                    )
                  }
                }
              })}
            </AsideMenuItemWithSub>
          )
        } else {
          return (
            <AsideMenuItem
              to={item.route}
              title={item.label}
              icon={`${icon_base_url}${item.icon}`}
              key={item.label}
            />
          )
        }
      })}
    </>
  )
}
