import {useContext} from 'react'
import Accordion from '../../../components/Accordion'
import LinkedTicketsTable from './tables/LinkedTicketsTable'
import {TicketDetailsPageContext} from '../context'
import {isEmpty} from '../../../utils/common'

const LinkedTicketsAccordion = () => {
  const {ticketDetails} = useContext(TicketDetailsPageContext)

  return (
    <Accordion
      defaultOpen={'All'}
      className='mb-8'
      headerClass='mb-0 mt-5'
      headerToggleClass='p-4 px-0 rounded-1 text-gray-900 fs-6 border-bottom bg-white'
      bodyClass='p-0'
      items={[
        {
          id: 'parent-tickets',
          title: 'Parent Tickets',
          content: <LinkedTicketsTable data={ticketDetails?.parent_cards} type='parent' />,
          show: !isEmpty(ticketDetails?.parent_cards),
        },
        {
          id: 'sub-tickets',
          title: 'Child Tickets',
          content: <LinkedTicketsTable data={ticketDetails?.child_cards} type='child' />,
          show: !isEmpty(ticketDetails?.child_cards),
        },
      ]}
    />
  )
}

export default LinkedTicketsAccordion
