import useApi from '../../../services/useApi'
import useFilters from '../../../hook/useFilters'
import moment from 'moment'

const useGetProfitability = () => {
  const Api = useApi()

  const initialFilters = {
    start_date: moment().startOf('year').format('YYYY-MM-DD'), // Start of the current year
    end_date: moment().format('YYYY-MM-DD'), // Today's date
  }

  const {filters, onDateChange} = useFilters(initialFilters, {
    sortType: 'number',
  })

  const {data: response, isFetching} = Api.useGetQuery('/analytics/profitability/report-summary', {
    queryId: 'profitability-summary',
    filters: filters,
    isToast: false,
    isErrorPageRedirection: false,
  })

  return {
    profitabilitySummaryData: response?.data || [],
    onDateChangeProfitabilitySummary: onDateChange,
    isLoading: isFetching,
  }
}

export default useGetProfitability
