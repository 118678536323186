import {Link} from 'react-router-dom'
import {} from '../../utils'
import Initials from '../../../../components/Initials'

const CustomersTableRow = ({row}: any) => {
  return (
    <tr>
      <td>
        <Link
          to={`/customers/all-customer/details?id=${row['id']}`}
          state={{
            id: row['id'],
          }}
          className='d-flex align-items-center flex-grow-1 text-start cursor-pointer'
        >
          <div className='symbol symbol-circle symbol-35px me-3 text-uppercase'>
            <Initials text={row?.customerName?.charAt(0)} />
          </div>
          <div className='d-flex flex-column'>
            <div className='align-items-center text-dark fw-semibold'>{row?.customerName}</div>
            <div className='align-items-center text-muted'>{row?.email}</div>
          </div>
        </Link>
      </td>
      <td>
        <span>{row.customerGroup}</span>
      </td>
      <td>
        <span>{row.salesRep}</span>
      </td>
      <td>
        <span>{row.orderCount}</span>
      </td>
      <td>
        <span>{row.totalCost}</span>
      </td>
      <td>
        <span>{row.revenue}</span>
      </td>
      <td>
        <span>{row.profit}</span>
      </td>
      <td>
        <span>{row.profit}</span>
      </td>
      <td className='text-end'>
        <span>{row.profitPercentage}%</span>
      </td>
    </tr>
  )
}

export default CustomersTableRow
