import {useContext} from 'react'
import {ProfitabilityContext} from '../../context'
import {DynamicTable} from '../../../../components/DynamicTable'
import {parseCustomerGroupRow, CUSTOMER_GROUP_COLUMNS} from '../../utils'
import CustomerGroupRow from './CustomerGroupTableRow'

const CustomerGroupTable = () => {
  const {CustomersGroupsData, isCustomersGroupsLoading} = useContext(ProfitabilityContext)
  
  return (
    <div className='card border'>
      <div className='card-body p-7'>
      <h2 className='mb-5 text-dark fw-bolder mb-8'>Customer Group</h2>
      <DynamicTable
          data={CustomersGroupsData}
          sortableColumns={CUSTOMER_GROUP_COLUMNS}
          loading={isCustomersGroupsLoading}
          noDataMessage='No customer group data found'
          tableClass='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-2 mb-0'
          TableRow={CustomerGroupRow}
          rowParsingFunction={parseCustomerGroupRow}
        />
      </div>
    </div>
  )
}

export default CustomerGroupTable
