import {PageLink} from '../../../_metronic/layout/core'

export const ReplenishmentPageBreadCrumbs: Array<PageLink> = [
  {
    title: 'Analytics',
    path: '/analytics/sold-products/dashboard',
    isSeparator: false,
    isActive: true,
  },
]

export const ReorderBreadCrumbs: Array<PageLink> = [
  {
    title: 'Analytics',
    path: '/analytics/sold-products/dashboard',
    isSeparator: false,
    isActive: true,
  },
  {
    title: 'Replenishment',
    path: '/analytics/replenishment/dashboard',
    isSeparator: false,
    isActive: true,
  },
]

export const supplierManagementBreadCrumbs: Array<PageLink> = [
  {
    title: 'Analytics',
    path: '/analytics/sold-products/dashboard',
    isSeparator: false,
    isActive: true,
  },
  {
    title: 'Replenishment',
    path: '/analytics/replenishment/dashboard',
    isSeparator: false,
    isActive: true,
  },
]

export const dataToMultiSelectOptions = (data: any, type: string) => {
  const res: any = []

  if (data?.[type]?.length) {
    data[type].forEach((i: any) => {
      res.push({
        value: i,
        label: i,
      })
    })
  }

  return res
}

export function extractValues(input: any) {
  if (input?.length) {
    return input.map((item: any) => item.value)
  } else {
    return []
  }
}
export const sampleBackOrderCSVData = [
  {SKU: 'SKU001', Qty: 10},
  {SKU: 'SKU002', Qty: 20},
  {SKU: 'SKU003', Qty: 30},
]

export const backOrderCsvConfig = {
  requiredHeaders: ['SKU', 'Qty'],
  duplicates: {sku: false},
  isNullable: false,
  isReplaceable: true,
  replacePartially: true,
  maxRows: 5000,
  types: {
    qty: 'integer',
  },
  customErrors: {
    headerMismatch: () => 'The CSV file must contain two columns: SKU, Qty',
    invalidFileType: () =>
      `The file you are trying to upload is not in a valid CSV format. Please upload a valid CSV file.`,
    emptyCell: (RowNumber: number, headerName: string) =>
      `The ${headerName} is blank in CSV row number ${RowNumber}.`,
    duplicateWithDefault: (skuName: any, rowNumber: number, headerName: string) =>
      `${headerName}: <strong>${skuName}</strong>, Row No. <strong>${rowNumber}</strong>`,
    replacePartially:
      'The following SKU(s) already exist in the system. Do you want to proceed with overwriting these data?',
    maxRowsLimit: (currentRows: number, maxLimit: number) =>
      `The current CSV contains ${currentRows} SKUs. The maximum allowed SKUs are ${maxLimit}. Please reduce the number of SKUs to proceed with the import.`,
    invalidDataType: (headerName: string, rowIndex: number) =>
      `Invalid ${headerName} found in rows: ${rowIndex}`,
  },
}


export function isProductDiscontinued(productTitle: string): boolean {
  if (!productTitle || typeof productTitle !== 'string') return true
  return productTitle.toLowerCase().includes('discontinued')
}

export const toSnakeCase = (str: string) => {
  return str
    .replace(/\s+/g, '_') // Replace spaces with underscores
    .replace(/([a-z])([A-Z])/g, '$1_$2') // Add underscore between lowercase and uppercase letters
    .replace(/([A-Z]+)([A-Z][a-z])/g, '$1_$2') // Handle acronyms (CBD2Store -> cbd2_store)
    .toLowerCase() // Convert everything to lowercase
}

export const dataToBackOrderCSV = (data: any) => {
  const results: any = []

  data.forEach((item: any) => {
    results.push({
      SKU: item?.child_sku,
      Qty: item?.qty || 0,
    })
  })

  return results
}

export const convertToLabelValue = (data: any) => {
  return data.map((item: any) => ({
    label: item,
    value: item,
  }))
}
