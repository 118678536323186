export const WEB_VISITORS_COLUMNS = [
  {label: 'Date', key: 'created_at', isSorted: true, headerStyle: 'min-w-120px'},
  {label: 'Customer', key: 'customer_name', isSorted: true, headerStyle: 'min-w-200px'},
  {label: 'Company', key: 'company_name', isSorted: true, headerStyle: 'min-w-175px'},
  {label: 'Channel', key: 'channel', isSorted: true, headerStyle: 'w-150px'},
  {label: 'IP Address', key: 'ip_address', isSorted: true, headerStyle: 'min-w-150px'},
  {label: 'IP Company', key: 'ip_company_name', isSorted: true, headerStyle: 'min-w-200px'},
  {label: 'Location', key: 'location', isSorted: true, headerStyle: 'min-w-200px'},
]

export const parseWebVisitorRow = (raw: any) => {
  return {
    id: raw?.id || '',
    name: raw?.customer_name || '-',
    email: raw?.email || '-',
    ip_address: raw?.ip_address || '-',
    company_name: raw?.company_name || '-',
    ip_company_name: raw?.ip_company_name || '-',
    location: raw?.location || '-',
    customerFirstNameLetter: raw?.customer_name?.[0]?.toUpperCase() || '',
    date: raw?.created_at,
    customer_id: raw?.customer_id || '',
    channel: raw?.channel || '-',
  }
}
