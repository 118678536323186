import {useContext} from 'react'
import {LiquidationReportPageContext} from '../../context'
import {DynamicTable} from '../../../../components/DynamicTable'
import ProductReportsTableRow from './LiquidationReportTableRow'

const LiquidationReportChildTable = ({childData}: any) => {
  const {isLoading, filters, onSortingChange} = useContext(LiquidationReportPageContext)

  const columns = [
    {key: '', headerStyle: 'min-w-40px w-40px', label: ''},
    {key: '', headerStyle: 'min-w-40px w-40px', label: ''}, // Product mark
    {key: 'name', headerStyle: 'min-w-450px w-450px', label: 'Product Name', isSorted: false}, // Product Name
    {key: 'sku', headerStyle: 'min-w-150px w-150px', label: 'SKU', isSorted: false}, // SKU
    {
      key: 'calculated_price',
      headerStyle: 'min-w-150px w-150px',
      label: 'BC Price',
      isSorted: true,
      style: 'justify-content-center',
    }, // SKU
    {
      key: '',
      headerStyle: 'min-w-175px w-175px',
      label: '',
      isSorted: false,
      style: 'justify-content-center'
    }, // Variant Count
    {
      key: 'inventory_level',
      headerStyle: 'min-w-175px w-175px',
      label: 'Inventory Packs',
      isSorted: false,
      style: 'justify-content-center'
    }, // Inventory Packs
    {
      key: 'blocked_quantity',
      headerStyle: 'min-w-200px w-200px',
      label: 'Blocked Quantity',
      isSorted: false,
      style: 'justify-content-center',
    }, // Blocked Quantity
    {
      key: 'pack_count',
      headerStyle: 'min-w-150px w-150px',
      label: 'Pack Count',
      isSorted: false,
      style: 'justify-content-center'
    }, // Pack Count
    {
      key: 'piece_count',
      headerStyle: 'min-w-150px w-150px',
      label: 'Piece Count',
      isSorted: false,
      style: 'justify-content-center'
    }, // Piece Count
    {
      key: 'price_per_piece',
      headerStyle: 'min-w-120px w-120px',
      label: 'Price/PC',
      isSorted: false,
      style: 'justify-content-center'
    }, // Price/PC
    {key: '', headerStyle: 'min-w-80px w-80px', label: ''}, // Product Name

  ]

  return (
    <DynamicTable
      id='product-report-child-table'
      data={childData}
      filters={filters}
      sortableColumns={columns}
      onSortingChange={onSortingChange}
      loading={isLoading}
      TableRow={ProductReportsTableRow}
      tableRowProps={{isChild: true}}
      tableClass='table gs-0 gy-3 align-middle my-3 table-row-bordered border price-list-child-table'
      permissionPath={'products_liquidation'}
    />
  )
}

export default LiquidationReportChildTable
