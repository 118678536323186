import {KTCard} from '../../../_metronic/helpers'
import CustomerChurnHeader from '../sections/header/CustomerChurnHeader'
import CustomerChurnTable from '../sections/table/CustomerChurnTable'
import useGetCustomerChurn from '../hooks/useGetCustomerChurn'
import {CustomerChurnContext} from '../context'
import {Pagination} from '../../../utils/pagination'

function CustomerChurnReport() {
  const {customers, isLoading, onSearch, onSortingChange, onPageChange, pagination} =
    useGetCustomerChurn()

  const contextValue = {
    customers,
    isLoading,
    onSearch,
    onSortingChange,
    onPageChange,
  }

  return (
    <KTCard>
      <CustomerChurnContext.Provider value={contextValue}>
        <CustomerChurnHeader />
        <CustomerChurnTable />
        <Pagination pagination={pagination} onPageChange={onPageChange} />
      </CustomerChurnContext.Provider>
    </KTCard>
  )
}

export default CustomerChurnReport
