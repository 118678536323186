import {useContext} from 'react'
import {DynamicTable} from '../../../../components/DynamicTable'
import CustomerChurnTableRow from './CustomerChurnTableRow'
import {parseCustomerChurnRow, CUSTOMER_CHURN_COLUMNS} from '../../utils'
import { CustomerChurnContext } from '../../context'

const CustomerChurnTable = () => {
  const {customers, isLoading, onSortingChange} = useContext(CustomerChurnContext)

  return (
    <DynamicTable
      data={customers}
      sortableColumns={CUSTOMER_CHURN_COLUMNS}
      loading={isLoading}
      noDataMessage='No customers found'
      onSortingChange={onSortingChange}
      tableClass='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-2'
      TableRow={CustomerChurnTableRow}
      rowParsingFunction={parseCustomerChurnRow}
    />
  )
}

export default CustomerChurnTable
