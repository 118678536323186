import {Pagination} from '../../../utils/pagination'
import {ProfitabilityContext} from '../context'
import useMeta from '../../../hook/useMeta'
import CustomersTable from '../sections/table/CustomersTable'
import {useContext} from 'react'

const Customers = ({isFullView}: any) => {
  useMeta('Customers Profitability')
  const {onPageChange, pagination} = useContext(ProfitabilityContext)

  return (
    <>
      {isFullView && (
        <h3 className='mb-10'>
          All Customer-Profit reports are generated from orders with a Completed status only.
        </h3>
      )}
      <CustomersTable isFullView={isFullView} />
      {isFullView && <Pagination pagination={pagination} onPageChange={onPageChange} />}
    </>
  )
}

export default Customers
