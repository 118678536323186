import {useEffect, useState} from 'react'
import {useQuery} from 'react-query'
import {getTableData, multyProductAddTags, getReplenishmentCsv} from '../services'
import useFilters from '../../../hook/useFilters'
import {getURLSearchParams, makeSemicolonSepratedValues} from '../../../utils/common'
import {useForm} from 'react-hook-form'
import useToastify from '../../../hook/useToastify'
import {useSelector} from 'react-redux'
import useApi from '../../../services/useApi'

export const useGetReplenishmentData = (): any => {
  const {toastMessage} = useToastify()
  const Api = useApi()

  const params = getURLSearchParams()
  const [isFetchingCsvData, setIsFetchingCsvData] = useState(false)
  const {register, watch, reset} = useForm({defaultValues: {skus: []}})
  const [selectedSkus, setSelectedSkus] = useState(watch())
  const {global_user, global_suppliers} = useSelector((state: any) => state.filters)
  let lastPayload: any = null;

  useEffect(() => {
    const subscription = watch((data) => {
      const newData: any = data
      setSelectedSkus(newData)
    })

    // Cleanup the subscription on component unmount
    return () => subscription.unsubscribe()
  }, [watch])

  const initialFilters = {
    page: 1,
    limit: 30,
    brand: '',
    classification: '',
    primary_supplier: global_suppliers.length ? makeSemicolonSepratedValues(global_suppliers) : '',
    date_range: '',
    sort_by: 'suggested_order_qty/-1',
    searchKey: '',
    searchValue: '',
    sale_history_months: 6,
    days_to_replenishment: 30,
    days_to_replenishment_45: 45,
    days_to_replenishment_60: 60,
    user: global_user?.value !== '' ? global_user?.value : '',
    tags: '',
    hide_discontinued: true,
    show_zero_qty_products: true,
    no_sold_days: false,
  }

  const {filters, setSingleFilter, setMultipleFilters} = useFilters({
    ...initialFilters,
    ...(params?.['page'] &&
      params?.['page'] > 0 && {
        page: +params['page'],
      }),
  })

  const {
    data: response,
    refetch,
    isFetching,
  } = useQuery(`get-Replenishment-data`, () => {
    return getTableData(`analytics/replenishment/aggregate/data`, filters)
  })

  useEffect(() => {
    refetch()
  }, [filters, refetch])

  if (!response?.['data']) {
    return []
  }

  const onFilter = (searchValue: any, searchKey: string) => {
    const commaSeparatedValue = searchValue.join(',')
    if (searchKey === 'brand') {
      setMultipleFilters({
        brand: commaSeparatedValue,
        page: 1,
      })
    } else if (searchKey === 'classification') {
      setMultipleFilters({
        classification: commaSeparatedValue,
        page: 1,
      })
    } else if (searchKey === 'primary_supplier') {
      setMultipleFilters({
        primary_supplier: commaSeparatedValue,
        page: 1,
      })
    }
  }

  const onSearch = (
    searchValue: string,
    hide_discontinued: boolean,
    show_zero_qty_products: boolean,
    no_sold_days: boolean
  ) => {
    setMultipleFilters({
      searchValue: searchValue,
      hide_discontinued: hide_discontinued,
      show_zero_qty_products: show_zero_qty_products,
      no_sold_days: no_sold_days,
      page: 1,
    })
  }

  const onPageChange = (page_number: number) => {
    setSingleFilter('page', page_number)
  }

  const onClickSorting = (sortBy: string, sortDirection: any) => {
    const direction = sortDirection === 'asc' ? 1 : -1
    const sortFlag = sortBy + '/' + direction
    setMultipleFilters({
      sort_by: sortFlag,
      page: 1,
    })
  }

  const onApplyFilters = (data: any) => {
    setMultipleFilters({
      page: 1,
      limit: 30,
      brand: data.brand !== '' ? data.brand : '',
      classification: data.classification !== '' ? data.classification : '',
      primary_supplier: data.primary_supplier !== '' ? data.primary_supplier : '',
      sale_history_months: data.sale_history_months,
      days_to_replenishment: data.days_to_replenishment,
      days_to_replenishment_45: data.days_to_replenishment_45,
      days_to_replenishment_60: data.days_to_replenishment_60,
      user: data.user,
      searchValue: data.search_value || '',
      tags: data.tags !== '' ? data.tags : '',
      hide_discontinued: data.hide_discontinued,
      show_zero_qty_products: data.show_zero_qty_products,
      no_sold_days: data.no_sold_days,
    })
  }

  const onResetFilters = () => {
    setMultipleFilters({
      page: 1,
      limit: 30,
      brand: '',
      classification: '',
      primary_supplier: '',
      date_range: '',
      sort_by: 'suggested_order_qty/-1',
      searchKey: '',
      searchValue: '',
      sale_history_months: 6,
      days_to_replenishment: 30,
      days_to_replenishment_45: 45,
      days_to_replenishment_60: 60,
      tags: '',
      hide_discontinued: true,
      show_zero_qty_products: true,
      no_sold_days: false,
    })
  }

  const handleResetClick = () => {
    reset() // This will clear the registered fields
    setSelectedSkus({skus: []})
  }

  const onMultyProductAddTags = async (data: any) => {
    const res = await multyProductAddTags(data)
    if (res.status === 200) {
      toastMessage('success', `Tags added successfully.`)
      return res?.data
    } else {
      toastMessage('error', `Something went wrong.`)
      return {}
    }
  }

  const onDownloadCsv = async (payload: any) => {
    const updatedFilters = {
      ...filters,
      filtered_skus: payload.filtered_skus || '',
    }
    setIsFetchingCsvData(true)
    const data = await getReplenishmentCsv(updatedFilters)
    let res = ''
    if (data?.status === 200) {
      res = data?.data?.message
      handleResetClick()
      refetch()
    } else {
      toastMessage('error', data?.data?.message)
    }
    setIsFetchingCsvData(false)
    return res
  }

  const onClassificationChange = (payload: any) => {
    if (JSON.stringify(payload) === JSON.stringify(lastPayload)) {
      return; // Prevent duplicate API calls
    }
    lastPayload = payload;

    return Api.post(`/analytics/replenishment/classifications`, payload, {
      successMessage: 'Classification updated successfully.',
      refetch: 'get-Replenishment-data',
    });
  };

  return {
    replenishment: response?.['data']?.['data'] || [],
    filters,
    isFetchingReplenishmentData: isFetching,
    pagination: response?.['data']?.['meta']?.['pagination'] || {},
    monthly_column: response?.['data']?.['meta']?.['month_rows'] || {},
    refetch,
    onFilter,
    onSearch,
    onPageChange,
    onClickSorting,
    onApplyFilters,
    onResetFilters,
    register,
    selectedSkus,
    handleResetClick,
    onMultyProductAddTags,
    onDownloadCsv,
    isFetchingCsvData: isFetchingCsvData,
    onClassificationChange
  }
}
