/* eslint-disable react-hooks/exhaustive-deps */
import {useState, useEffect} from 'react'
import {KTSVG, useDebounce} from '../_metronic/helpers'

interface SearchProps {
  onSearch: (searchTerm: string) => void
  defaultValue?: string
  placeholder?: string
  classNames?: string
  isDisable?: boolean
  isClearInput?: boolean
}

function Search({
  onSearch,
  defaultValue,
  placeholder,
  classNames,
  isDisable,
  isClearInput = false,
}: SearchProps) {
  const [searchTerm, setSearchTerm] = useState<string>(defaultValue || '')

  const debouncedSearchTerm = useDebounce(searchTerm, 500)

  useEffect(() => {
    if (onSearch) {
      if (typeof onSearch === 'function' && debouncedSearchTerm !== undefined) {
        onSearch(debouncedSearchTerm)
      }
    }
  }, [debouncedSearchTerm])

  useEffect(() => {
    // Reset search term when `defaultValue` changes to an empty string
    if (defaultValue === '' && defaultValue !== searchTerm) {
      setSearchTerm(defaultValue)
    }
  }, [defaultValue])

  useEffect(() => {
    // If `isClearInput` is true, reset the search term
    if (isClearInput) {
      setSearchTerm('')
    }
  }, [isClearInput])

  const handleSearch = (e: any) => {
    setSearchTerm(e.target.value)
  }

  const handleClear = () => {
    setSearchTerm('')
    onSearch('')
  }

  return (
    <div className='d-flex align-items-center position-relative'>
      <KTSVG
        path='/media/icons/duotune/general/gen021.svg'
        className='svg-icon-1 position-absolute ms-6'
      />
      <input
        type='text'
        data-kt-user-table-filter='search'
        className={`form-control ${
          classNames ? classNames : 'form-control-solid w-250px'
        } ps-14 pe-10`}
        placeholder={placeholder ? placeholder : 'Search'}
        value={searchTerm}
        onChange={handleSearch}
        disabled={isDisable}
      />
      {searchTerm && !isDisable && (
        <i
          className='bi bi-x position-absolute end-0 me-4 cursor-pointer fs-2'
          onClick={handleClear}
        ></i>
      )}
    </div>
  )
}

export default Search
