import Date from '../../Date/Date'

export const notificationIconBaseUrl = '/media/ad-theme-icons/notifications/'

export const getNotificationLinkPath = (data: any) => {
  const {
    event_type,
    order_id,
    po_id,
    ticket_id,
    inquiry_id,
    project_id,
    customer_id,
    status,
    type,
    card_identifier,
    bop_id,
  } = data

  switch (event_type) {
    case 'order_created':
    case 'order_updated':
      return `/orders/all-orders/summary?id=${order_id}`
    case 'po_created':
    case 'order_placed':
      if (status === 'completed' || status === 'deleted') {
        return `/orders/bo/po-request/${status}/${po_id}/details`
      } else if (status === 'pending') {
        return `/orders/bo/po-request/pending/${po_id}/pending`
      }
      return `/orders/bo/po-request/${status}/${po_id}/${status}`
    case 'block_order_created':
      return `/orders/blocked-orders/summary?id=${order_id}`
    case 'unlock_product':
      const boStatus =
        type === 'bulkorder'
          ? 'available-products'
          : type === 'preorder'
          ? 'pre-order-products'
          : null
      return `/orders/bo/manage/products/${boStatus}/${bop_id}/info`
    case 'price_list_rule_created':
    case 'price_list_rule_updated':
      return '/products/price-list/rules'
    case 'guest_product_inquiry':
    case 'registered_user_product_inquiry':
      return `/products/inquiries/all-inquiries?isOpen=true&id=${inquiry_id}`
    case 'ticket_created':
    case 'ticket_assigned':
    case 'ticket_updated':
    case 'ticket_assigned_by_mapping':
      return `/projects/all-projects/${project_id}/ticket/${ticket_id}/${card_identifier}`
    case 'user_themes_updated':
      return '/storefronts/builds'
    case 'customer_created':
      return `/customers/all-customer/details?id=${customer_id}`
    case 'user_created':
      return '/settings/users'
    case 'job_failed':
      return '/settings/jobs'
    case 'webhook_inactive':
      return `/settings/jobs/webhooks`
    default:
      return ``
  }
}

const truncateText = (text: any, maxLength: any) => {
  if (!text) return ''
  return text.length > maxLength ? text.slice(0, maxLength) + '...' : text
}

export const renderNotificationMessage = (data: any) => {
  const {
    event_type,
    customer_name,
    order_id,
    po_id,
    order_value,
    line_item_count,
    rep_name,
    product_name,
    rule_name,
    new_user_name,
    job_name,
    webhook_scope,
    created_at,
    created_by_user_name,
    updated_by_user_name,
    admin_user_name,
    invitee,
    card_identifier,
    bop_id,
    updater_user_name
  } = data
  switch (event_type) {
    case 'order_created':
      return (
        <>
          <div className='mb-2'>
            <span className='text-primary fw-bold'>{customer_name}</span> has placed an{' '}
            <span className='fw-bold'>order</span>{' '}
            <span className='text-primary fw-bold'>#{order_id}</span>
          </div>
          <div>
            <span className='fw-bold'>Line items:</span> {line_item_count}
            <br />
            <span className='fw-bold'>Value:</span> ${order_value}
            <br />
            {rep_name ? (
              <span>
                <span className='fw-bold'>Sales Rep:</span> {rep_name}
              </span>
            ) : null}
          </div>
          <div className='text-gray-500 mt-2'>
            <Date date={created_at} isDynamicFormat={true} />
          </div>
        </>
      )
    case 'order_updated':
      return (
        <>
          <div className='mb-2'>
            <span className='text-primary fw-bold'>{admin_user_name}</span> has updated the{' '}
            <span className='fw-bold'>order</span>{' '}
            <span className='text-primary fw-bold'>#{order_id}</span>
          </div>
          <div className='text-gray-500'>
            <Date date={created_at} isDynamicFormat={true} />
          </div>
        </>
      )
    case 'po_created':
      return (
        <>
          <div className='mb-2'>
            <span className='text-primary fw-bold'>{customer_name}</span> has created the{' '}
            <span className='fw-bold'>PO</span>{' '}
            <span className='text-primary fw-bold'>#{po_id}</span> for the bulk order
          </div>
          <div className='text-gray-500'>
            <Date date={created_at} isDynamicFormat={true} />
          </div>
        </>
      )
    case 'order_placed':
      return (
        <>
          <div className='mb-2'>
            <span className='text-primary fw-bold'>{admin_user_name}</span> has created an order
            for <span className='fw-bold'>PO</span>{' '}
            <span className='text-primary fw-bold'>#{po_id}</span>
          </div>
          <div className='text-gray-500'>
            <Date date={created_at} isDynamicFormat={true} />
          </div>
        </>
      )
    case 'unlock_product':
      return (
        <>
          <div className='mb-2'>
            <span className='text-primary fw-bold'>BOP #{bop_id}</span> is available for placing
            an order
          </div>
          <div className='text-gray-500'>
            <Date date={created_at} isDynamicFormat={true} />
          </div>
        </>
      )
    case 'block_order_created':
      return (
        <>
          <div className='mb-2'>
            <span className='text-primary fw-bold'>{admin_user_name}</span> has marked{' '}
            <span className='fw-bold'>Order</span>{' '}
            <span className='text-primary fw-bold'>#{order_id}</span> as the blocked order
          </div>
          <div className='text-gray-500'>
            <Date date={created_at} isDynamicFormat={true} />
          </div>
        </>
      )
    case 'price_list_rule_created':
      return (
        <>
          <div className='mb-2'>
            <span className='text-primary fw-bold'>{admin_user_name}</span> has created a new{' '}
            <span className='fw-bold'>Price list rule:</span>{' '}
            <span className='text-primary fw-bold'>{rule_name}</span>
          </div>
          <div className='text-gray-500'>
            <Date date={created_at} isDynamicFormat={true} />
          </div>
        </>
      )
    case 'price_list_rule_updated':
      return (
        <>
          <div className='mb-2'>
            <span className='text-primary fw-bold'>{updater_user_name}</span> has updated a{' '}
            <span className='fw-bold'>Price list rule:</span>{' '}
            <span className='text-primary fw-bold'>{rule_name}</span>
          </div>
          <div className='text-gray-500'>
            <Date date={created_at} isDynamicFormat={true} />
          </div>
        </>
      )
    case 'guest_product_inquiry':
      return (
        <>
          <div className='mb-2'>
            Guest customer : <span className='text-primary fw-bold'>{customer_name}</span> has
            inquired for the{' '}
            <span className='text-primary'>{truncateText(product_name, 60)}</span>
          </div>
          <div className='text-gray-500'>
            <Date date={created_at} isDynamicFormat={true} />
          </div>
        </>
      )
    case 'registered_user_product_inquiry':
      return (
        <>
          <div className='mb-2'>
            <span className='text-primary fw-bold'>{customer_name}</span> has inquired for the{' '}
            <span className='text-primary'>{truncateText(product_name, 60)}</span>
          </div>
          <div className='text-gray-500'>
            <Date date={created_at} isDynamicFormat={true} />
          </div>
        </>
      )
    case 'customer_created':
      return (
        <>
          <div className='mb-2'>
            <span className='text-primary fw-bold'>{customer_name}</span> has created an account
          </div>
          <div className='text-gray-500'>
            <Date date={created_at} isDynamicFormat={true} />
          </div>
        </>
      )
    case 'user_themes_updated':
      return (
        <>
          <div className='mb-2'>
            <span className='text-primary fw-bold'>{admin_user_name}</span> has created a new
            build for the storefront
          </div>
          <div className='text-gray-500'>
            <Date date={created_at} isDynamicFormat={true} />
          </div>
        </>
      )
    case 'ticket_created':
      return (
        <>
          <div className='mb-2'>
            <span className='text-primary fw-bold'>{created_by_user_name}</span> has created the
            ticket <span className='text-primary fw-bold'>#{card_identifier}</span>
          </div>
          <div className='text-gray-500'>
            <Date date={created_at} isDynamicFormat={true} />
          </div>
        </>
      )
    case 'ticket_assigned':
      return (
        <>
          <div className='mb-2'>
            <span className='text-primary fw-bold'>{updated_by_user_name}</span> has assigned you
            the ticket <span className='text-primary fw-bold'>#{card_identifier}</span>
          </div>
          <div className='text-gray-500'>
            <Date date={created_at} isDynamicFormat={true} />
          </div>
        </>
      )
    case 'ticket_assigned_by_mapping':
      return (
        <>
          <div className='mb-2'>
            Ticket <span className='text-primary fw-bold'>#{card_identifier}</span> assigned to
            you
          </div>
          <div className='text-gray-500'>
            <Date date={created_at} isDynamicFormat={true} />
          </div>
        </>
      )
    case 'ticket_updated':
      return (
        <>
          <div className='mb-2'>
            <span className='text-primary fw-bold'>{updated_by_user_name}</span> has updated the
            ticket <span className='text-primary fw-bold'>#{card_identifier}</span>
          </div>
          <div className='text-gray-500'>
            <Date date={created_at} isDynamicFormat={true} />
          </div>
        </>
      )
    case 'user_created':
      return (
        <>
          <div className='mb-2'>
            <span className='text-primary fw-bold'>{new_user_name}</span> has been invited to the
            Admin app portal by <span className='text-primary fw-bold'>{invitee}</span>
          </div>
          <div className='text-gray-500'>
            <Date date={created_at} isDynamicFormat={true} />
          </div>
        </>
      )
    case 'job_failed':
      return (
        <>
          <div className='mb-2'>
            <span className='fw-bold'>{job_name}</span> job has failed
          </div>
          <div className='text-gray-500'>
            <Date date={created_at} isDynamicFormat={true} />
          </div>
        </>
      )
    case 'webhook_inactive':
      return (
        <>
          <div className='mb-2'>
            <span className='fw-bold'>{webhook_scope}</span> webhook was inactive
          </div>
          <div className='text-gray-500'>
            <Date date={created_at} isDynamicFormat={true} />
          </div>
        </>
      )
    default:
      return <>Unknown event</>
  }
}
