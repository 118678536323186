import {createContext} from 'react'

export const ProfitabilityContext = createContext({
  profitabilityData: [],
  isLoading: false,
  onSearch: (value: string) => {},
  onSortingChange: (key: string, order: string) => {},
  onPageChange: (page: number) => {},
  pagination: {},
  onDateChangeCustomers: (startDate: any, endDate: any) => {},

  CustomersGroupsData: [],
  isCustomersGroupsLoading: false,
  
  profitabilitySummaryData: [],
  isProfitabilitySummaryLoading: false,
})

