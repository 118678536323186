import {Link} from 'react-router-dom'
import Date from '../../../../components/Date/Date'
import Initials from '../../../../components/Initials'
import {formatDate} from '../../../../utils/date'

const CustomerChurnTableRow = ({row}: any) => {
  const {name, email, id, customerFirstNameLetter} = row?.customerName
  return (
    <tr>
      <td>
        <Link
          to={`/customers/all-customer/details?id=${id}`}
          state={{
            id: id,
          }}
          className='d-flex align-items-center flex-grow-1 text-start cursor-pointer'
        >
          <div className='symbol symbol-circle symbol-35px me-3'>
            <Initials text={customerFirstNameLetter} />
          </div>
          <div className='d-flex flex-column'>
            <div className='align-items-center text-dark fw-semibold'>{name}</div>
            <div className='align-items-center text-muted'>{email}</div>
          </div>
        </Link>
      </td>
      <td>{row.lastOrderValue}</td>
      <td>{row.totalOrders}</td>
      <td>{formatDate(row?.expectedOrderDate, true)}</td>
      <td>
        <Date date={row?.lastOrderDate} isDynamicFormat={false} />
      </td>
    </tr>
  )
}

export default CustomerChurnTableRow
