import {PageLink, PageTitle} from '../../../_metronic/layout/core'

const NavigationTitle = ({
  breadcrumbs = true,
  disableBreadCrumbs = false,
  defaultBreadcrumbs,
  title,
  isLoading,
}: {
  breadcrumbs?: boolean | PageLink[]
  disableBreadCrumbs?: boolean
  defaultBreadcrumbs?: boolean | PageLink[]
  title?: string | boolean
  isLoading?: boolean
}) => {
  return (
    <>
      {breadcrumbs !== false &&
        (!isLoading ? (
          <PageTitle
            breadcrumbs={
              disableBreadCrumbs
                ? []
                : typeof breadcrumbs !== 'boolean'
                ? breadcrumbs
                : typeof defaultBreadcrumbs !== 'boolean'
                ? defaultBreadcrumbs
                : []
            }
          >
            {title !== false ? title : null}
          </PageTitle>
        ) : (
          <PageTitle
            className='placeholder bg-gray-200 w-150px h-30px rounded-2'
            content={
              typeof breadcrumbs !== 'boolean' ? (
                <span className='placeholder bg-gray-200 w-200px h-10px mt-2 rounded-3'></span>
              ) : null
            }
          >
            {' '}
          </PageTitle>
        ))}
    </>
  )
}

export default NavigationTitle
