import {useForm} from 'react-hook-form'
import {ConfirmationModal} from '../../../../components/ConfirmationModal'
import {yupResolver} from '@hookform/resolvers/yup'
import {createBackOrderValidationSchema} from '../../validations'
import InputText from '../../../../components/InputText/InputText'
import InputNumber from '../../../../components/InputNumber/InputNumber'
import {useContext, useEffect, useState} from 'react'
import useBackOrderOperations from '../../hooks/useBackOrderOperations'
import {BackOrderContext} from '../../context'

const BackOrderCreateModal = ({showModal, setShowModal, isEdit = false, productData}: any) => {
  const {validateChildSku, createBackOrderSKU, updateBackOrderSKU, isValidatingSku, isLoading} =
    useBackOrderOperations()
  const {isLoading: isDataLoading} = useContext(BackOrderContext)
  const [isSkuValid, setIsSkuValid] = useState(false)

  const {
    register,
    handleSubmit,
    formState: {errors},
    setError,
    reset,
    watch,
    clearErrors,
  } = useForm<any>({
    resolver: yupResolver(createBackOrderValidationSchema),
    defaultValues: {
      sku: '',
      qty: null,
    },
  })

  useEffect(() => {
    if (isEdit && productData) {
      reset({
        sku: productData.child_sku,
        qty: productData.qty,
      })
    }
    if (watch('sku') === '') {
      setIsSkuValid(false)
    }
  }, [isEdit, productData, reset, watch])

  const handleModalClose = () => {
    reset()
    clearErrors()
    setIsSkuValid(false)
    setShowModal(false)
  }

  const handleModalAction = async (data: any) => {
    if (isEdit) {
      updateBackOrderSKU(productData?.id, {qty: Number(data?.qty?.trim())}, setError)
    } else {
      if (!isSkuValid) {
        const {status} = await validateChildSku(data?.sku?.trim(), setError)
        if (status !== 200) return
      }
      setIsSkuValid(true)
      createBackOrderSKU([{...data, sku: data?.sku.trim()}], setError)
    }
  }

  const handleFocusQtyField = async () => {
    const skuValue = watch('sku')
    if (!isEdit && skuValue && !isSkuValid && !errors?.sku) {
      clearErrors('sku')
      const {status} = await validateChildSku(skuValue.trim(), setError)
      if (status === 200) {
        setIsSkuValid(true)
        clearErrors('sku')
      } else {
        setIsSkuValid(false)
      }
    }
  }

  return (
    <ConfirmationModal
      show={showModal}
      onClose={handleModalClose}
      onAction={handleSubmit(handleModalAction)}
      title={isEdit ? 'Edit SKU' : 'Add SKU'}
      actionName={isEdit ? 'Save' : 'Add'}
      actionBtnClass='btn-primary'
      isOperationLoading={isLoading}
      isDataLoading={isDataLoading}
      isDisabled={isLoading || Object.keys(errors).length > 0}
      body={
        <form>
          <div className='position-relative mb-8'>
            <InputText
              id='SKU'
              name='sku'
              label='SKU (Enter a Child SKU Only)'
              register={register('sku')}
              error={errors?.sku}
              className='mb-3'
              disabled={isEdit}
              onChange={() => {
                clearErrors('sku')
                setIsSkuValid(false)
              }}
            />
            {isValidatingSku ? (
              <div className='position-absolute top-50 end-0 translate-middle-y me-3 mt-4'>
                <span className='spinner-border spinner-border-md align-middle ms-2 text-success w-20px h-20px'></span>
              </div>
            ) : isSkuValid ? (
              <div className='position-absolute top-50 end-0 translate-middle-y me-3 mt-4'>
                <i className='fa-solid fa-circle-check text-success fs-3'></i>
              </div>
            ) : null}
          </div>
          <InputNumber
            id='Qty'
            name='qty'
            label='Qty'
            register={register('qty')}
            error={errors?.qty}
            className='mb-3'
            isFloat={false}
            maxLength={6}
            onFocus={handleFocusQtyField}
          />
        </form>
      }
    />
  )
}

export default BackOrderCreateModal
