import {useContext} from 'react'
import useApi from '../../../../services/useApi'
import {ProjectDetailsContext} from '../../context'
import useFilters from '../../../../hook/useFilters'

const useGetGanttChartData = () => {
  const Api = useApi()
  const {projectId} = useContext(ProjectDetailsContext)

  const initialFilters = {
    search: true,
    report_type: 'gantt_chart',
  }

  const {filters, onSearch} = useFilters(initialFilters)

  const {data: response, isFetching, refetch} = Api.useGetQuery(`/agile/projects/${projectId}/gantt-chart`, {
    queryId: `${projectId}-gantt-chart-data`,
    filters: filters,
  })

  return {
    ganttChartData: response?.data?.gantt_chart || [],
    filtersData: response?.data?.filters || [],
    isLoading: isFetching,
    onSearch,
    refetch
  }
}

export default useGetGanttChartData
