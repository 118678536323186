import {Navigate, Routes, Route, Outlet, useLocation, useNavigate} from 'react-router-dom'
import {NavigationProps, NavItem} from './NavigationTypes'
import {NavigationTabs} from './components/NavigationTabs'
import NavigationTitle from './components/NavigationTitle'

const Navigation = ({
  id,
  navigationData,
  baseUrl,
  className = '',
  defaultKey = navigationData?.[0]?.key,
  breadcrumbs = false,
  isLoading,
  pageTitle,
  paramKey = 'view',
  viewSingleComponent = navigationData?.length === 1 ? false : true,
  disableSearchParams = false,
  hideNavigation = false,
}: NavigationProps) => {
  const location = useLocation()
  const NavigateToPath = useNavigate()
  const visibleNavigationData = navigationData.filter((item) => item.show !== false)

  const dynamicRoutings = () => {
    if (disableSearchParams || !viewSingleComponent) {
      return (
        <Routes>
          <Route
            element={
              <>
                <Outlet />
              </>
            }
          >
            {visibleNavigationData.map((item) => (
              <Route
                key={item.key}
                path={!viewSingleComponent ? '/' : `${item.key}`}
                element={
                  <>
                    <NavigationTitle
                      breadcrumbs={item.breadcrumbs}
                      disableBreadCrumbs={item.disableBreadCrumbs}
                      defaultBreadcrumbs={breadcrumbs}
                      isLoading={isLoading}
                      title={
                        item?.title === true || item?.title === undefined
                          ? pageTitle === true
                            ? item?.label
                            : typeof pageTitle === 'string'
                            ? pageTitle
                            : pageTitle === false
                            ? false
                            : item?.label
                          : typeof item?.title === 'string'
                          ? item?.title
                          : false
                      }
                    />
                    {item.component}
                  </>
                }
              />
            ))}

            {viewSingleComponent && (
              <Route index element={<Navigate to={`${baseUrl}/${defaultKey}`} replace />} />
            )}
          </Route>
        </Routes>
      )
    } else {
      const view = new URLSearchParams(location.search).get(paramKey)
      const isValidView = visibleNavigationData.some((item) => item.key === view)
      const activePath = `${location.pathname}?${paramKey}=${view}`
      const activeSearchComponent: NavItem | undefined = !disableSearchParams
        ? visibleNavigationData.find((item) => item.key === view)
        : undefined

      if (
        location.pathname === baseUrl &&
        activePath !== `${baseUrl}?${paramKey}=${isValidView ? view : defaultKey}`
      ) {
        NavigateToPath(`${baseUrl}?${paramKey}=${isValidView ? view : defaultKey}`)
      }

      return (
        <>
          <NavigationTitle
            breadcrumbs={activeSearchComponent?.breadcrumbs}
            disableBreadCrumbs={activeSearchComponent?.disableBreadCrumbs}
            defaultBreadcrumbs={breadcrumbs}
            isLoading={isLoading}
            title={
              activeSearchComponent?.title === true || activeSearchComponent?.title === undefined
                ? pageTitle === true
                  ? activeSearchComponent?.label
                  : typeof pageTitle === 'string'
                  ? pageTitle
                  : pageTitle === false
                  ? false
                  : activeSearchComponent?.label
                : typeof activeSearchComponent?.title === 'string'
                ? activeSearchComponent?.title
                : false
            }
          />
          <Navigate to={`${baseUrl}?${paramKey}=${isValidView ? view : defaultKey}`} replace />
          {activeSearchComponent?.component}
        </>
      )
    }
  }

  return (
    <>
      {!hideNavigation && viewSingleComponent && (
        <NavigationTabs
          navigations={visibleNavigationData}
          disableSearchParams={disableSearchParams}
          className={className}
          baseUrl={baseUrl}
          paramKey={paramKey}
        />
      )}
      {dynamicRoutings()}
    </>
  )
}

export default Navigation
