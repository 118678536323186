import {PageLink} from '../../../_metronic/layout/core'

export const ReplenishmentPageBreadCrumbs: Array<PageLink> = [
  {
    title: 'Analytics',
    path: '/analytics/sold-products/dashboard',
    isSeparator: false,
    isActive: true,
  },
]

export const ReorderBreadCrumbs: Array<PageLink> = [
  {
    title: 'Analytics',
    path: '/analytics/sold-products/dashboard',
    isSeparator: false,
    isActive: true,
  },
  {
    title: 'Replenishment',
    path: '/analytics/replenishment/dashboard',
    isSeparator: false,
    isActive: true,
  },
]

export const supplierManagementBreadCrumbs: Array<PageLink> = [
  {
    title: 'Analytics',
    path: '/analytics/sold-products/dashboard',
    isSeparator: false,
    isActive: true,
  },
  {
    title: 'Replenishment',
    path: '/analytics/replenishment/dashboard',
    isSeparator: false,
    isActive: true,
  },
]

export const dataToMultiSelectOptions = (data: any, type: string) => {
  const res: any = []

  if (data?.[type]?.length) {
    data[type].forEach((i: any) => {
      res.push({
        value: i,
        label: i,
      })
    })
  }

  return res
}

export function isProductDiscontinued(productTitle: string): boolean {
  if (!productTitle || typeof productTitle !== 'string') return true
  return productTitle.toLowerCase().includes('discontinued')
}

export const toSnakeCase = (str: string) => {
  return str
    .replace(/\s+/g, '_') // Replace spaces with underscores
    .replace(/([a-z])([A-Z])/g, '$1_$2') // Add underscore between lowercase and uppercase letters
    .replace(/([A-Z]+)([A-Z][a-z])/g, '$1_$2') // Handle acronyms (CBD2Store -> cbd2_store)
    .toLowerCase() // Convert everything to lowercase
}

// classified as page

export const classifiedAsTableColumns = [
  {
    key: 'classified-as',
    label: 'Classified As',
    headerStyle: 'min-w-400px',
  },
  {
    key: 'product-count',
    label: 'Product Count',
    headerStyle: 'w-200px',
  },
  {
    key: 'action',
    label: 'Action',
    headerStyle: 'w-80px',
  },
]

export const classifiedAsTableRowData = (row: any) => {
  return {
    id: row?.id,
    classifiedAs: row?.name || '-',
    productCount: row?.product_count || 0,
  }
}
