import {PageLink} from '../../_metronic/layout/core'
import Navigation from '../../components/Navigation/Navigation'
import Profitability from './layouts/Profitability'

const ProfitabilityPageCrumbs: Array<PageLink> = [
  {
    title: 'Analytics',
    path: '/analytics/sold-products/dashboard',
    isSeparator: false,
    isActive: true,
  },
]

const ProfitabilityPage = () => {
  return (
    <Navigation
      baseUrl='/analytics/profitability'
      breadcrumbs={ProfitabilityPageCrumbs}
      defaultKey='dashboard'
      hideNavigation={true}
      pageTitle='Profitability'
      disableSearchParams={true}
      navigationData={[
        {
          key: 'dashboard',
          component: <Profitability />,
        },
        {
          key: 'customers',
          component: <Profitability />,
        },
      ]}
    />
  )
}

export default ProfitabilityPage
