import {useContext, useEffect, useState} from 'react'
import {ReplenishmentContext} from '../context'
import {Controller, useForm} from 'react-hook-form'
import {useGetFilters} from '../hooks/useGetFilters'
import {dataToMultiSelectOptions, formDataToFiltersValue} from '../utils'
import ReactSelect from '../../../components/ReactSelect'
import useGetAllMappedUsers from '../hooks/useGetAllMappedUsers'
import MultipleSelect from '../../../components/MultiSelect'
import Search from '../../../components/Search'
import {dataToTagSelectList} from '../../../utils/common'
import {useDispatch, useSelector} from 'react-redux'
import {setUserFilter, setSupplierFilter} from '../../../redux/ReplenishmentActions'
import {ConfirmationModal} from '../../../components/ConfirmationModal'
import ProductTagsCreateModal from './model/ProductTagsCreateModal'
import usePermission from '../../../hook/usePermission'

const ReplenishmentSearchComponent = () => {
  const dispatch = useDispatch()
  const {hasPermission} = usePermission()
  const isPermission = hasPermission('analytics_replenishment_dashboard', 'write')

  const {global_user, global_suppliers} = useSelector((state: any) => state.filters)
  const [showModal, setShowModal] = useState(false)
  const [message, setMessage] = useState('')

  const defaultValues = {
    search: '',
    name: global_user?.value !== '' ? global_user : '',
    brands: [],
    classifications: [],
    primary_supplier: global_suppliers || [],
    sale_history_months: 6,
    days_to_replenishment: 30,
    days_to_replenishment_45: 45,
    days_to_replenishment_60: 60,
    tags: [],
    page: 1,
    limit: 50,
    hide_discontinued: true,
    show_zero_qty_products: true,
    no_sold_days: false,
  }

  const {control, register, getValues, reset, setValue, watch} = useForm({
    defaultValues: defaultValues,
  })
  const {tags, replenishment} = useContext(ReplenishmentContext)

  const formValues = watch()

  const {tableFilters, handleUserChange, onChangeDiscontinueFilters, onResetDropdownFilters} =
    useGetFilters()
  const {mappedUsers, isFetchingData} = useGetAllMappedUsers()

  const [showTagsModal, setShowTagsModal] = useState(false)

  const {
    onApplyFilters,
    onSearch,
    selectedSkus,
    isFetchingReplenishmentData,
    onDownloadCsv,
    isFetchingCsvData,
  } = useContext(ReplenishmentContext)

  const onClickApplyfilter = async () => {
    const values = getValues()
    onApplyFilters(formDataToFiltersValue(values))
  }

  const onResetFilters = () => {
    reset(defaultValues)
    setValue('name', '')
    setValue('primary_supplier', [])
    dispatch(setSupplierFilter([]))
    dispatch(setUserFilter(''))
    onClickApplyfilter()
    onResetDropdownFilters()
  }

  // HANDLE SEARCH ...
  useEffect(() => {
    if (formValues['search'] || formValues['search'] === '') {
      onSearch &&
        onSearch(
          formValues['search'],
          formValues['hide_discontinued'],
          formValues['show_zero_qty_products'],
          formValues['no_sold_days']
        )
      // reset({...defaultValues, search: formValues['search']})
    }
    // eslint-disable-next-line
  }, [formValues['search']])
  // HANDLE SEARCH ...

  // HANDLE USER ...
  const handleUser = (user: string) => {
    setValue('primary_supplier', [])
    setValue('classifications', [])
    setValue('brands', [])
    dispatch(setSupplierFilter([]))
    handleUserChange(user)
    if (!user) {
      dispatch(setUserFilter(''))
    } else {
      dispatch(setUserFilter(user))
    }
  }
  const handleDiscontinueFlagChange = (e: any) => {
    onChangeDiscontinueFilters(e?.target?.checked)
  }

  const onSupplierChange = (value: any) => {
    dispatch(setSupplierFilter(value))
  }

  async function downloadCsvFile() {
    const payload = {
      filtered_skus: selectedSkus?.['skus']?.join(','),
    }
    const res: any = await onDownloadCsv(payload)
    if (res !== '') {
      setShowModal(true)
      setMessage(res)
    }
  }

  return (
    <>
      <div className='mb-15'>
        <div className='d-flex'>
          <Controller
            name='search'
            control={control}
            render={({field: {value, onChange}}) => (
              <Search
                onSearch={(searchTerm: string) => {
                  onChange(searchTerm)
                }}
                defaultValue={value}
              />
            )}
          />

          <div className='d-flex align-items-center ms-10'>
            <div className='d-flex align-items-center'>
              <h5 className='fw-semibold mb-0 me-3'>Months: </h5>
              <div className='search-param'>
                <select
                  className='form-select'
                  data-kt-select2='true'
                  data-placeholder='Select Page Limit'
                  data-allow-clear='true'
                  defaultValue={6}
                  {...register('sale_history_months')}
                >
                  <option value={1}>Months: 01</option>
                  <option value={2}>Months: 02</option>
                  <option value={3}>Months: 03</option>
                  <option value={4}>Months: 04</option>
                  <option value={5}>Months: 05</option>
                  <option value={6}>Months: 06</option>
                </select>
              </div>
            </div>

            <div className='d-flex align-items-center ms-10'>
              <h5 className='fw-semibold mb-0 me-3'>Days: </h5>
              <div className='search-param'>
                <select
                  className='form-select'
                  data-kt-select2='true'
                  data-placeholder='Select Page Limit'
                  data-allow-clear='true'
                  defaultValue={30}
                  {...register('days_to_replenishment')}
                >
                  <option value={7}>Days: 07</option>
                  <option value={15}>Days: 15</option>
                  <option value={30}>Days: 30</option>
                  <option value={45}>Days: 45</option>
                  <option value={60}>Days: 60</option>
                  <option value={90}>Days: 90</option>
                  <option value={180}>Days: 180</option>
                </select>
              </div>
            </div>

            {/* <div className='d-flex align-items-center ms-10'>
              <h5 className='fw-semibold mb-0 me-3'>No Sold Days:</h5>
              <div className='search-param'>
                <select
                  className='form-select'
                  data-kt-select2='true'
                  data-placeholder='Select Page Limit'
                  data-allow-clear='true'
                  defaultValue={30}
                  {...register('no_sold_days')}
                >
                  <option value={7}>Days: 07</option>
                  <option value={15}>Days: 15</option>
                  <option value={30}>Days: 30</option>
                  <option value={45}>Days: 45</option>
                  <option value={60}>Days: 60</option>
                  <option value={90}>Days: 90</option>
                  <option value={180}>Days: 180</option>
                </select>
              </div>
            </div> */}
          </div>
        </div>

        <div className='d-flex mt-7'>
          <div className='d-flex align-items-center'>
            <input
              className='form-check-input widget-9-check me-2'
              type='checkbox'
              {...register('hide_discontinued')}
              onChange={(e: any) => handleDiscontinueFlagChange(e)}
            />
            <h6 className='form-check-label mb-0'>Hide Discontinued Products</h6>
          </div>
          <div className='d-flex align-items-center ms-10'>
            <input
              className='form-check-input widget-9-check me-2'
              type='checkbox'
              {...register('show_zero_qty_products')}
            />
            <h6 className='form-check-label mb-0'>Show "0" Qty Products</h6>
          </div>
          <div className='d-flex align-items-center ms-10'>
            <input
              className='form-check-input widget-9-check me-2'
              type='checkbox'
              {...register('no_sold_days')}
            />
            <h6 className='form-check-label mb-0'>Show Not sold Products Only</h6>
          </div>
        </div>
      </div>
      {tableFilters && (
        <>
          <h4 className='mb-6'>More filter</h4>
          <div className='bottom-filter-section d-flex align-items-center-1 mb-5 row'>
            <div className='w-20'>
              <div className='search-param'>
                <Controller
                  name='name'
                  control={control}
                  render={({field: {value, name, onChange}}) => (
                    <ReactSelect
                      name={name}
                      value={value}
                      defaultValue={global_user?.value !== '' ? global_user : ''}
                      label={'Select User'}
                      placeholder='Select User'
                      options={mappedUsers}
                      onChange={(e: any) => {
                        onChange(e?.target?.value)
                        handleUser(e?.target?.value?.value)
                      }}
                      isLoading={isFetchingData}
                    />
                  )}
                />
              </div>
            </div>
            <div className='w-20'>
              <div className='search-param select-2-css-placeholder'>
                <>
                  <label className={'form-label'}>{'Primary Supplier'}</label>
                  <Controller
                    name='primary_supplier'
                    control={control}
                    render={({field: {value, onChange}}) => (
                      <MultipleSelect
                        options={dataToMultiSelectOptions(tableFilters, 'primary_supplier')}
                        handleSelected={(selectedValues) => {
                          onSupplierChange(selectedValues)
                          onChange(selectedValues)
                        }}
                        defaultValues={value}
                        hasSelectAll={false}
                        placeholder='Select Primary Supplier'
                      />
                    )}
                  />
                </>
              </div>
            </div>

            <div className='w-20'>
              <div className='search-param select-2-css-placeholder'>
                <>
                  <label className={'form-label'}>{'Classifications'}</label>
                  <Controller
                    name='classifications'
                    control={control}
                    render={({field: {value, onChange}}) => (
                      <MultipleSelect
                        options={dataToMultiSelectOptions(tableFilters, 'classification')}
                        handleSelected={onChange}
                        defaultValues={value}
                        hasSelectAll={false}
                        placeholder='Select Classification'
                      />
                    )}
                  />
                </>
              </div>
            </div>
            <div className='w-20'>
              <div className='search-param select-2-css-placeholder'>
                <>
                  <label className={'form-label'}>{'Brands'}</label>
                  <Controller
                    name='brands'
                    control={control}
                    render={({field: {value, onChange}}) => (
                      <MultipleSelect
                        options={dataToMultiSelectOptions(tableFilters, 'brand')}
                        handleSelected={onChange}
                        defaultValues={value}
                        hasSelectAll={false}
                        placeholder='Select Brand'
                      />
                    )}
                  />
                </>
              </div>
            </div>

            <div className='w-20'>
              <div className='search-param select-2-css-placeholder'>
                <>
                  <label className={'form-label'}>{'Tags'}</label>
                  <Controller
                    name='tags'
                    control={control}
                    render={({field: {value, onChange}}) => (
                      <MultipleSelect
                        options={dataToTagSelectList(tags)}
                        handleSelected={onChange}
                        defaultValues={value}
                        placeholder='Select Tags'
                        className='text-uppercase'
                      />
                    )}
                  />
                </>
              </div>
            </div>

            {/* Button  */}
            <div className='d-flex align-items-center mb-5'>
              <button
                type='button'
                className='btn btn-outline'
                onClick={onResetFilters}
                disabled={isFetchingReplenishmentData}
              >
                Reset Filters
              </button>
              <button
                className='btn btn-primary ms-3'
                onClick={onClickApplyfilter}
                disabled={isFetchingReplenishmentData}
              >
                Apply Filters
              </button>
              {selectedSkus?.['skus'].length && isPermission ? (
                <button onClick={() => setShowTagsModal(true)} className='btn btn-primary ms-3'>
                  <i className='bi bi-plus-lg'></i>
                  Add Tags
                </button>
              ) : (
                <></>
              )}
              {replenishment && replenishment.length > 0 ? (
                <button
                  type='button'
                  className='btn btn-primary ms-4'
                  onClick={downloadCsvFile}
                  disabled={isFetchingCsvData}
                >
                  {isFetchingCsvData ? 'Downloading...' : 'Download CSV'}
                </button>
              ) : null}
              <ProductTagsCreateModal
                showModal={showTagsModal}
                setShowModal={setShowTagsModal}
                isEdit={false}
                productData={selectedSkus?.['skus']}
                isAssignToMultiProducts={true}
              />
            </div>
          </div>
        </>
      )}
      <ConfirmationModal
        show={showModal}
        onClose={() => {
          setShowModal(false)
          setMessage('')
        }}
        title={'Generating Your CSV File'}
        disableAction={true}
        onAction={() => {
          setShowModal(false)
          setMessage('')
        }}
        okayBtnClass={'btn-primary'}
        body={
          <div>
            <p>We are generating the data you requested for download as a CSV file.</p>
            <p>
              This process may take a few minutes. Once complete, the CSV will be sent to your
              email: <b>{message.split(':')[1]}</b>
            </p>
          </div>
        }
      />
    </>
  )
}

export default ReplenishmentSearchComponent
