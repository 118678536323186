import {Link} from 'react-router-dom'
import Initials from '../../../../components/Initials'
import Date from '../../../../components/Date/Date'

const WebVisitorsRow = ({row}: any) => {
  return (
    <tr>
      <td>
        <Date date={row?.date} isDynamicFormat={false} />
      </td>
      <td>
        <Link
          to={`/customers/all-customer/details?id=${row['customer_id']}`}
          state={{
            id: row['id'],
          }}
          className='d-flex align-items-center flex-grow-1 text-start cursor-pointer'
        >
          <div className='symbol symbol-circle symbol-35px me-3'>
            <Initials text={row.customerFirstNameLetter} />
          </div>
          <div className='d-flex flex-column'>
            <div className='align-items-center text-dark fw-semibold'>{row.name}</div>
            <div className='align-items-center text-muted'>{row.email}</div>
          </div>
        </Link>
      </td>
      <td>{row.company_name || '-'}</td>
      <td>{row.channel || '-'}</td>
      <td>{row.ip_address || '-'}</td>
      <td>{row.ip_company_name || '-'}</td>
      <td>{row.location || '-'}</td>
    </tr>
  )
}

export default WebVisitorsRow
