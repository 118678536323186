import {useContext} from 'react'
import {CustomerChurnContext} from '../../context'
import Search from '../../../../components/Search'

const CustomerChurnHeader = () => {
  const {onSearch} = useContext(CustomerChurnContext)

  return (
    <div className='d-flex flex-wrap justify-content-between align-items-center mb-10'>
      <div className='d-flex align-items-center'>
        <Search
          onSearch={(searchTerm: string) => {
            onSearch && onSearch(searchTerm)
          }}
        />
      </div>
    </div>
  )
}

export default CustomerChurnHeader
