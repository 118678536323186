import ReplenishmentData from './pages/ReplenishmentData'
import Reordering from './pages/Reordering'
import {
  ReplenishmentPageBreadCrumbs,
} from './utils'
import ReplenishmentDashboard from './pages/ReplenishmentDashboard'
import Classification from './pages/Classification'
import { Navigation } from '../../components/Navigation'

function ReplenishmentPage() {
  return (
    <Navigation
      baseUrl='/analytics/replenishment/dashboard'
      breadcrumbs={ReplenishmentPageBreadCrumbs}
      defaultKey='dashboard'
      navigationData={[
        {
          key: 'dashboard',
          label: 'Dashboard',
          component: <ReplenishmentDashboard />,
        },
        {
          key: 'replenishment',
          label: 'Replenishment',
          component: <ReplenishmentData />,
        },
        {
          key: 'reorder',
          label: 'Reordering',
          component: <Reordering />,
        },
        {
          key: 'classification',
          label: 'Classification',
          component: <Classification />,
        },
      ]}
    />
  )
}

export default ReplenishmentPage
