
const CustomerGroupRow = ({ row }:any) => {
  return (
    <tr>
      <td>
        <span>{row.customerGroup}</span>
      </td>
      <td>
        <span>{row.orderCount}</span>
      </td>
      <td>
        <span>{row.totalCost}</span>
      </td>
      <td>
        <span>{row.revenue}</span>
      </td>
      <td>
        <span>{row.profit}</span>
      </td>
      <td className='text-end'>
        <span>{row.profitPercentage}%</span>
      </td>
    </tr>
  )
};

export default CustomerGroupRow;
