const SectionLoading = () => {
    return (
      <div className='section-overlay theme-custom-loading'>
        <div className='section-loading-inner'>
          <span className='spinner-border text-primary' role='status'></span>
          <span className='text-gray-800 fs-6 fw-semibold mt-5'>Loading...</span>
        </div>
      </div>
    )
  }
  
  export default SectionLoading
  