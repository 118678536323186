import {useContext, useEffect, useState} from 'react'
import {ReorderingContext} from '../context'
import {Controller, useForm} from 'react-hook-form'
import MultipleSelect from '../../../components/MultiSelect'
import ReactSelect from '../../../components/ReactSelect'
import useGetAllMappedUsers from '../hooks/useGetAllMappedUsers'
import {useGetReorderingFilters} from '../hooks/useGetReorderingFilters'
import {dataToMultiSelectOptions, formDataToFiltersValueReorder} from '../utils'
import {useDispatch, useSelector} from 'react-redux'
import {setUserFilter, setSupplierFilter} from '../../../redux/ReplenishmentActions'
import usePermission from '../../../hook/usePermission'
import Search from '../../../components/Search'
import {ConfirmationModal} from '../../../components/ConfirmationModal'

const ReorderSearchComponent = () => {
  const dispatch = useDispatch()
  const {global_user, global_suppliers} = useSelector((state: any) => state.filters)
  const {hasPermission} = usePermission()

  const defaultValues = {
    page: 1,
    limit: 50,
    filter_key: 'sku',
    search: '',
    primary_supplier: global_suppliers || [],
    user: global_user?.value !== '' ? global_user : '',
  }

  const {control, getValues, reset, setValue, watch} = useForm<any>({
    defaultValues: defaultValues,
  })

  const formValues = watch()
  const {mappedUsers, isFetchingData} = useGetAllMappedUsers()
  const {reorderFilters, handleUserChange, onResetDropdownFilters} = useGetReorderingFilters()
  const [openClearAllModal, setOpenClearAllModal] = useState(false)

  const {
    isLoading,
    isOpeationLoading,
    reorderingData,
    onClearAll,
    onSearch,
    onDownloadCsv,
    isFetchingCsvData,
    onApplyFilters,
    onResetFilters,
  } = useContext(ReorderingContext)

  const onClickApplyfilter = async () => {
    const values = getValues()
    onApplyFilters(formDataToFiltersValueReorder(values))
  }

  const onClickReset = async () => {
    reset(defaultValues)
    setValue('user', '')
    setValue('primary_supplier', [])
    dispatch(setSupplierFilter([]))
    dispatch(setUserFilter(''))
    onClickApplyfilter()
    onResetDropdownFilters()
  }

  // HANDLE SEARCH ...
  useEffect(() => {
    if (formValues['search'] || formValues['search'] === '') {
      onSearch && onSearch(formValues['search'], formValues['filter_key'])
    }
    // eslint-disable-next-line
  }, [formValues['search']])

  const handleUser = (user: string) => {
    setValue('primary_supplier', [])
    dispatch(setSupplierFilter([]))
    handleUserChange(user)
    if (!user) {
      dispatch(setUserFilter(''))
    } else {
      dispatch(setUserFilter(user))
    }
  }
  const onSupplierChange = (value: any) => {
    dispatch(setSupplierFilter(value))
  }

  return (
    <>
      <div className='d-flex mb-10'>
        <div className='search-param select-2-css-placeholder'>
          <Controller
            control={control}
            name='filter_key'
            render={({field: {name, value, onChange}}) => (
              <select
                className='form-select'
                data-kt-select2='true'
                data-placeholder='Select option'
                data-allow-clear='true'
                defaultValue={'sku'}
                name={name}
                value={value}
                onChange={(e: any) => {
                  onChange(e?.target?.value)
                }}
              >
                <option value='sku'>SKU</option>
                <option value='title'>Title</option>
                <option value='primary_supplier'>Supplier Name</option>
              </select>
            )}
          />
        </div>
        <div className='ms-3'>
          <Controller
            name='search'
            control={control}
            render={({field: {value, onChange}}) => (
              <Search
                onSearch={(searchTerm: string) => {
                  onChange(searchTerm)
                }}
                defaultValue={value}
              />
            )}
          />
        </div>
      </div>

      <div className='bottom-filter-section d-flex align-items-center justify-content-between mb-5'>
        <div className='d-flex'>
          {reorderFilters && (
            <>
              <div className='w-225px me-4'>
                <div className='search-param'>
                  <Controller
                    name='user'
                    control={control}
                    render={({field: {value, name, onChange}}) => (
                      <ReactSelect
                        name={name}
                        value={value}
                        defaultValue={global_user?.value !== '' ? global_user : ''}
                        label={'Select User'}
                        placeholder='Select User'
                        options={mappedUsers}
                        onChange={(e: any) => {
                          onChange(e?.target?.value)
                          handleUser(e?.target?.value?.value)
                        }}
                        isLoading={isFetchingData}
                      />
                    )}
                  />
                </div>
              </div>
              <div className='w-225px me-4'>
                <div className=''>
                  {
                    <>
                      <div className='bottom-filter-section'>
                        <div className=''>
                          <div className='search-param'>
                            <>
                              <label className={'form-label'}>{'Primary Supplier'}</label>
                              <Controller
                                name='primary_supplier'
                                control={control}
                                render={({field: {value, onChange}}) => (
                                  <MultipleSelect
                                    options={dataToMultiSelectOptions(
                                      reorderFilters,
                                      'primary_supplier'
                                    )}
                                    handleSelected={(selectedValues) => {
                                      onSupplierChange(selectedValues)
                                      onChange(selectedValues)
                                    }}
                                    defaultValues={value}
                                    hasSelectAll={false}
                                    placeholder='Select Primary Supplier'
                                  />
                                )}
                              />
                            </>
                          </div>
                        </div>
                      </div>
                    </>
                  }
                </div>
              </div>
            </>
          )}

          {reorderFilters && (
            <>
              <div className='mt-8'>
                <button
                  type='button'
                  className='btn text-decoration-underline p-0 py-2'
                  onClick={() => {
                    onClickReset()
                    onResetFilters()
                  }}
                  disabled={isLoading}
                >
                  Reset Filter
                </button>
              </div>
            </>
          )}
        </div>

        {hasPermission('analytics_replenishment_dashboard', 'write') && (
          <>
            <div>
              {reorderingData?.length ? (
                <>
                  <button
                    type='button'
                    className='btn btn-outline'
                    onClick={() => setOpenClearAllModal(true)}
                  >
                    Clear all Table
                  </button>
                  <button
                    type='button'
                    className='btn btn-primary ms-4'
                    onClick={onDownloadCsv}
                    disabled={isFetchingCsvData}
                  >
                    {isFetchingCsvData ? 'Downloading...' : 'Download CSV'}
                  </button>
                </>
              ) : null}
            </div>
          </>
        )}
      </div>

      {openClearAllModal && (
        <ConfirmationModal
          show={openClearAllModal}
          onClose={() => setOpenClearAllModal(false)}
          isOperationLoading={isOpeationLoading}
          isDataLoading={isOpeationLoading}
          onAction={onClearAll}
          body={
            <>
              <p className='mb-2'>Do you really want to clear all table?</p>
              <p>By clearing the table, all the reordering counts will be erased from the replenishment report. It will also remove the records from the reordering page.</p>
            </>
          }
        />
      )}
    </>
  )
}

export {ReorderSearchComponent}