import {formatPrice} from '../../../utils/common'

const shortNames: Record<number, string> = {
  96: 'NE', // Non-ENDS
  98: 'CP', // Custom Pricing
  45: 'T1', // Tier 1 Prices
  1: 'WH', // Wholesale Approved
  48: 'T2', // Tier 2 Prices
  91: 'TP', // Tier Pro Pricing
  90: 'VP', // Value Tier Pricing
  28: 'INT', // International
}

function formatNumber(num: any) {
  console.log('04-02 num: ', num)
  if (num >= 1e9) {
    return (num / 1e9).toFixed(1) + 'B'
  } else if (num >= 1e6) {
    return (num / 1e6).toFixed(1) + 'M'
  } else if (num >= 1e3) {
    return (num / 1e3).toFixed(1) + 'K'
  }
  return num.toString()
}

export const CUSTOMER_GROUP_COLUMNS = [
  {key: 'customerGroup', label: 'Customer Group', headerStyle: 'min-w-250px'},
  {key: 'orderCount', label: 'Order', headerStyle: 'min-w-100px'},
  {key: 'totalCost', label: 'Total Cost', headerStyle: 'min-w-150px'},
  {key: 'revenue', label: 'Revenue', headerStyle: 'min-w-150px'},
  {key: 'profit', label: 'Profit', headerStyle: 'min-w-150px'},
  {
    key: 'profitPercentage',
    label: 'Profit(%)',
    headerStyle: 'w-100px',
    style: 'justify-content-end',
  },
]

export const parseCustomerGroupRow = (data: any) => {
  return {
    customerGroup: data.customer_group_name || '-',
    orderCount: data.total_orders || 0,
    totalCost: formatPrice(data.total_cost, true),
    revenue: formatPrice(data.total_revenue, true),
    profit: formatPrice(data.total_profit, true),
    profitPercentage: data.profit_percentage || 0,
  }
}

export const PROFITABILITY_COLUMNS = [
  {key: 'customerName', label: 'Customer Name', headerStyle: 'min-w-200px'},
  {key: 'customerGroup', label: 'Customer Group', headerStyle: 'min-w-250px'},
  {key: 'salesRep', label: 'Sales Rep', headerStyle: 'min-w-200px'},
  {key: 'orderCount', label: 'Order', headerStyle: 'min-w-50px'},
  {key: 'totalCost', label: 'Total Cost', headerStyle: 'min-w-120px'},
  {key: 'revenue', label: 'Revenue', headerStyle: 'min-w-120px'},
  {key: 'profitPercentage', label: 'Profit(%)', headerStyle: 'w-100px',style: 'justify-content-end'},
]

export const PROFITABILITY_COLUMNS_WithShorting = [
  { key: 'name', label: 'Customer Name', isSorted: true,headerStyle: 'min-w-200px' },
  { key: 'customer_group_name', label: 'Customer Group', isSorted: true,headerStyle: 'min-w-250px' },
  { key: 'rep_name', label: 'Sales Rep', isSorted: true,headerStyle: 'min-w-200px' },
  { key: 'total_orders', label: 'Order', isSorted: true,headerStyle: 'min-w-50px' },
  { key: 'total_cost', label: 'Total Cost', isSorted: true,headerStyle: 'min-w-150px' },
  { key: 'total_profit', label: 'Profit', isSorted: true,headerStyle: 'min-w-120px' },
  { key: 'total_revenue', label: 'Revenue', isSorted: true,headerStyle: 'min-w-120px' },
  { key: 'profit_percentage', label: 'Profit(%)', style: 'justify-content-end',headerStyle: 'w-100px' },
];

export const parseProfitabilityRow = (data: any) => {
  return {
    id: data.customer_id || '-',
    customerName: data.name || '-',
    email: data.email || '-',
    customerGroup: data.customer_group_name || '-',
    salesRep: data.rep_name || '-',
    orderCount: data.total_orders || 0,
    totalCost: formatPrice(data.total_cost, true),
    revenue: formatPrice(data.total_revenue, true) || 0,
    profit: formatPrice(data.total_profit, true) || 0,
    profitPercentage: data.profit_percentage || 0,
  }
}

export function PieCharData({piedata, pielabel}: any) {
  const DistributorData = {
    labels: pielabel || [],
    datasets: [
      {
        label: 'Qty',
        data: piedata || [],
        backgroundColor: ['rgba(28, 100, 242)', 'rgba(230, 0, 50)'],
        borderWidth: 1,
        spacing: 2,
      },
    ],
  }
  return DistributorData
}

interface BarChartArgs {
  label: (string | number)[]
  data: (string | number)[]
}

export function BarChartData({data, label}: BarChartArgs) {
  const slicedData = data.slice(0, 5)
  const slicedLabel = label.slice(0, 5)
  const DistributorData = {
    labels: slicedLabel || [],
    datasets: [
      {
        label: 'Profit(%)',
        backgroundColor: 'rgba(0, 92, 230, 0.2)',
        borderColor: 'rgba(0, 92, 230, 1)',
        borderWidth: 0,
        hoverBackgroundColor: 'rgba(0, 92, 230, 1)',
        hoverBorderColor: 'rgba(33, 87, 141, 1)',
        hoverBorderWidth: 0,
        borderRadius: 5,
        barThickness: 20,
        data: slicedData || [],
      },
    ],
  }
  return DistributorData
}

export function getBarChartOptions(mappedLabels: any) {
  return {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        titleColor: '#fff',
        bodyColor: '#fff',
        padding: 10,
        callbacks: {
          label: function (context: any) {
            const rawLabel = mappedLabels[context.label] || ''
            const price = formatPrice(`${context.raw}`, false)
            const wrappedLabel = rawLabel.match(/.{1,40}/g) || [rawLabel] 

            return [...wrappedLabel, "Profit : " + price] 
          },
        },
      },
    },
    scales: {
      x: {
        beginAtZero: true,
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          display: false,
        },
        ticks: {
          stepSize: 10000000,
          callback: function (value: number) {
            return formatNumber(value)
          },
        },
      },
    },
  }
}

export const pieChartOptions = {
  plugins: {
    legend: {
      display: false,
    },
  },
  cutout: '85%',
  tooltip: {
    enabled: false,
  },
}

export function transformToBarChartData(data: any[]) {
  return {
    label: data?.map(
      (item) => shortNames[item?.customer_group_id] || `G${item?.customer_group_id}`
    ),
    data: data?.map((item) => item?.total_profit),
  }
}

export const formatPieChartData = (data: any) => {
  return {
    piedata: [data?.revenue, data?.profit], // Use revenue and profit as values
    pielabel: ['Revenue', 'Profit'], // Labels for the chart
  }
}

export function mapCustomerGroups(data: any) {
  const result: any = {}

  data.forEach((item: any) => {
    // Check if the short key exists in shortNames and map it to the customer group name
    const shortKey = shortNames[item.customer_group_id]
    if (shortKey) {
      result[shortKey] = item.customer_group_name
    }
  })

  return result
}
