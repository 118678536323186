import useFilters from '../../../hook/useFilters'
import {runSpecificTask} from '../services'
import {useState} from 'react'
import useToastify from '../../../hook/useToastify'
import useApi from '../../../services/useApi'

function useGetJobData(job_id: string) {
  const Api = useApi()
  const [isProcessing, setIsProcessing] = useState(false)
  const {toastMessage} = useToastify()
  const initialFilters = {
    job_id: job_id,
  }
  const {filters} = useFilters(initialFilters)

  const {
    data: response,
    refetch,
    isLoading,
  } = Api.useGetQuery(`/settings/job`, {
    filters: filters,
    queryId: `get-job-data`,
    isToast: false,
    isErrorPageRedirection: false,
  })

  const runTask = async (name: string) => {
    setIsProcessing(true)
    const res = await runSpecificTask(name)
    if (res?.['id']) {
      refetch()
      toastMessage('success', 'Task Scheduled Successfully.')
      setIsProcessing(false)
    }
  }

  return {
    jobData: response?.data || [],
    isLoading: isLoading,
    runTask,
    isProcessing,
    refetchJobData: refetch,
  }
}

export default useGetJobData
