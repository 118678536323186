import useApi from '../../../services/useApi'
import {useEffect, useState} from 'react'

const options = [
  {label: 'Classification', value: 'classification'},
  {label: 'Blocked Qty', value: 'reserved_quantity'},
  {label: 'Available', value: 'quantity_available'},
  {label: 'Safety Stock', value: 'safetystock_quantity'},
  {label: 'Incoming', value: 'quantity_incoming'},
  {label: 'Backorder', value: 'backorder_quantity'},
  {label: 'Total Sold (30)', value: 'total_sold_30'},
  {label: 'Incremental Qty', value: 'incremental_quantity'},
  {label: 'Case Quantity', value: 'case_qty'},
  {label: 'Suggested Order Qty', value: 'suggested_order_qty'},
  {label: 'Suggested Order Qty 45', value: 'suggested_order_qty_45'},
  {label: 'Suggested Order Qty 60', value: 'suggested_order_qty_60'},
  {label: 'To Order Quantity', value: 'to_order_qty'},
  {label: 'Item To Be Ordered', value: 'item_order_qty'},
  {label: 'Available + Incoming', value: 'total_quantity'},
  {label: 'Out of Stock Days', value: 'days_out_of_stock'},
  {label: 'Cost', value: 'cost'},
  {label: 'Total Cost', value: 'total_cost'},
  {label: 'Supplier Company Name', value: 'primary_supplier'},
  {label: 'Weeks On Hand', value: 'weeks_on_hand'},
  {label: 'Turn Rate', value: 'turn_rate'},
]


const useGetSelectedTableColumns = () => {
  const [selectedColumns, setSelectedColumns] = useState<any[]>(options)

  const isColumnSelected = (column: string) => {
    const selectedColumnsArr = selectedColumns?.map((value: any) => value.value)
    return selectedColumnsArr.includes(column)
  }

  const Api = useApi()
  const {data: response, isFetching} = Api.useGetQuery('/analytics/replenishment/columns/listing?type=replenishment')

  useEffect(() => {
    const userSelectedColumns =
      options.filter((option) => response?.data?.columns?.includes(option.value)) || []
    if (!isFetching) {
      setSelectedColumns(response?.data?.is_filter_applied === true ? userSelectedColumns : options)
    }
  }, [isFetching, response])

  return {
    allTableColumns: options,
    selectedColumns: selectedColumns,
    setSelectedColumns: setSelectedColumns,
    isColumnSelected: isColumnSelected,
  }
}

export default useGetSelectedTableColumns
