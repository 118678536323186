import {PageLink} from '../../_metronic/layout/core'
import Navigation from '../../components/Navigation/Navigation'
import WebVisitors from './layouts/WebVisitors'

const WebVisitorsPageCrumbs: Array<PageLink> = [
  {
    title: 'Analytics',
    path: '/analytics/sold-products/dashboard',
    isSeparator: false,
    isActive: true,
  },
]

const WebVisitorsPage = () => {
  return (
    <Navigation
      baseUrl='/analytics/web-visitors'
      breadcrumbs={WebVisitorsPageCrumbs}
      defaultKey='web-visitors'
      navigationData={[
        {
          key: 'web-visitors',
          label: 'Web Visitors',
          component: <WebVisitors />,
        },
      ]}
    />
  )
}

export default WebVisitorsPage
