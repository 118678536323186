import {useContext, useState} from 'react'
import {GanttChartPageContext} from '../../context'
import Search from '../../../../components/Search'
import {RadioGroup} from '../../../../components/RadioGroup'
import {isEmpty} from '../../../../utils/common'
import {useDynamicGanttDetails} from '../../utils'
import { SideFilter } from '../../../../components/SideFilter'
import useSaveFilter from '../../hooks/useSaveFilter'
// import {SideFilter} from '../../../../components/SideFilter'
// import useSaveFilter from '../../hooks/useSaveFilter'

const GanttChartPageHeader = () => {
  const {
    ganttChartData,
    filtersData,
    isLoadingGanttChartData,
    chartView,
    setChartView,
    chartState,
    onSearchGanttChartData,
    refetchGanttChartData,
  } = useContext(GanttChartPageContext)
  const {startDate, endDate, duration} = useDynamicGanttDetails(chartState)
  // const {isOperationLoading, onSaveFilter} = useSaveFilter(refetchGanttChartData)
  // const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const hasData = !isEmpty(ganttChartData?.data)
  const {isOperationLoading, onSaveFilter} = useSaveFilter(refetchGanttChartData)
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  return (
    <>
      <div className='mb-10'>
        <div
          className={`d-flex ${hasData ? 'justify-content-between' : 'justify-content-end'} mb-8`}
        >
          {hasData && <Search onSearch={onSearchGanttChartData} />}
          <div className='d-flex align-items-center gap-5'>
            {hasData && (
              <>
                <RadioGroup
                  id='gantt-chart-view'
                  name='gantt-chart-view'
                  label='Month View'
                  value='month'
                  checked={chartView === 'month'}
                  onChange={(e: any) => setChartView(e.target.value)}
                />
                <RadioGroup
                  id='gantt-chart-view'
                  name='gantt-chart-view'
                  label='Week View'
                  value='week'
                  checked={chartView === 'week'}
                  onChange={(e: any) => setChartView(e.target.value)}
                />
              </>
            )}
            <SideFilter
              filterData={filtersData}
              onApply={onSaveFilter}
              isOperationLoading={isOperationLoading || isLoadingGanttChartData}
              onCancel={() => setIsDrawerOpen(false)}
              isDrawerOpen={isDrawerOpen}
              setIsDrawerOpen={setIsDrawerOpen}
              filterType={'gantt_chart'}
            />
          </div>
        </div>
        {hasData && (
          <div className='d-flex gap-10'>
            <div className='d-flex gap-3'>
              <span className='fw-bold'>Start Date:</span>
              <span className='text-gray-600'>{startDate}</span>
            </div>
            <div className='d-flex gap-3'>
              <span className='fw-bold'>End Date:</span>
              <span className='text-gray-600'>{endDate}</span>
            </div>
            <div className='d-flex gap-3'>
              <span className='fw-bold'>Duration:</span>
              <span className='text-gray-600'>{duration}</span>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default GanttChartPageHeader
