import {useContext} from 'react'
import ProductTagsTableRow from './ProductTagsTableRow'
import DynamicTable from '../../../../components/DynamicTable/DynamicTable'
import {parseProductTagsTableRowData} from '../../../analyticsReplenishment/utils'
import { ProductsTagsContext } from '../../context'
import OverlayModal from '../model/OverlayModalProductsTags'

function ProductTagsTable() {
  const {productsTagsList, isLoading, setCheckedRows, filters, onSortingChange} =
  useContext(ProductsTagsContext)

  const sortableColumns = [
    {key: 'name', label: 'Product Name', headerStyle: 'min-w-400px'},
    {label: 'SKU', headerStyle: 'min-w-150px'},
    {key: 'tags', label: 'Tags', headerStyle: 'min-w-200px'},
    {
      key: 'action',
      label: 'Action',
      headerStyle: 'w-80px text-center',
      render: (row: any) => <OverlayModal row={row} />,
    },
  ]

  return (
    <DynamicTable
      id='products-tags-table'
      data={productsTagsList}
      sortableColumns={sortableColumns}
      TableRow={ProductTagsTableRow}
      loading={isLoading}
      setCheckedRows={setCheckedRows}
      checkedRowId={'sku'}
      filters={filters}
      onSortingChange={onSortingChange}
      permissionPath={'analytics_replenishment_product tags'}
      rowParsingFunction={parseProductTagsTableRowData}
      requiredOutputKeys={['sku', 'variantSku']}
    />
  )
}

export default ProductTagsTable
