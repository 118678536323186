import {useState, useEffect} from 'react'
import {useQuery} from 'react-query'
import Papa from 'papaparse'
import {clearAllReorders, getReorderTableData, getCsvData} from '../services'
import useFilters from '../../../hook/useFilters'
import useToastify from '../../../hook/useToastify'
import {getCurrentDate} from '../../../utils/date'
import {getURLSearchParams, makeSemicolonSepratedValues} from '../../../utils/common'
import {useSelector} from 'react-redux'

export const useGetReorderingData = (): any => {
  const {toastMessage} = useToastify()
  const params = getURLSearchParams()
  const [isFetchingCsvData, setIsFetchingCsvData] = useState(false)
  const [isOpeationLoading, setIsOpeationLoading] = useState(false)
  const {global_user, global_suppliers} = useSelector((state: any) => state.filters)
  const initialFilters = {
    page: 1,
    limit: 50,
    sort_by: 'primary_supplier/1',
    searchKey: 'sku',
    searchValue: '',
    primary_supplier: global_suppliers.length ? makeSemicolonSepratedValues(global_suppliers) : '',
    user: global_user?.value !== '' ? global_user?.value : '',
  }

  const {filters, setSingleFilter, setMultipleFilters} = useFilters({
    ...initialFilters,
    ...(params?.['page'] &&
      params?.['page'] > 0 && {
        page: +params['page'],
      }),
  })

  const {
    data: response,
    refetch,
    isFetching,
  } = useQuery(`get-reordering-data`, () => {
    return getReorderTableData(`/analytics/replenishment/po/reorder`, filters)
  })

  useEffect(() => {
    refetch()
  }, [filters, refetch])

  if (!response?.['data']) {
    return []
  }

  const onSearch = (searchValue: string, searchKey: string) => {
    setMultipleFilters({
      searchValue: searchValue,
      searchKey: searchKey,
      page: 1,
    })
  }

  const onPageChange = (page_number: number) => {
    setSingleFilter('page', page_number)
  }

  const onClearAll = async () => {
    setIsOpeationLoading(true)
    const res = await clearAllReorders()
    if (res.status === 200) {
      toastMessage('success', `Data removed successfully.`)
      refetch()
    }
    setIsOpeationLoading(false)
  }
  const onClickSorting = (sortBy: string, sortDirection: any) => {
    const direction = sortDirection === 'asc' ? 1 : -1
    const sortFlag = sortBy + '/' + direction
    setMultipleFilters({
      sort_by: sortFlag,
      page: 1,
    })
  }

  const onApplyFilters = (data: any) => {
    setMultipleFilters({
      page: 1,
      limit: 50,
      searchKey: data.filter_key || '',
      searchValue: data.search_value || '',
      primary_supplier: data.primary_supplier !== '' ? data.primary_supplier : '',
      user: data.user,
    })
  }

  const onResetFilters = () => {
    setMultipleFilters({
      page: 1,
      limit: 50,
      sort_by: 'title/1',
      searchKey: 'sku',
      searchValue: '',
      primary_supplier: '',
      user: '',
    })
  }

  const onDownloadCsv = async () => {
    setIsFetchingCsvData(true)
    const jsonData = await getCsvData(filters)

    if (jsonData.length) {
      const csv = Papa.unparse(jsonData)

      const blob = new Blob([csv], {type: 'text/csv'})
      const url = URL.createObjectURL(blob)
      const link = document.createElement('a')

      link.href = url
      link.setAttribute('download', `reorder-${getCurrentDate()}.csv`)

      document.body.appendChild(link)

      link.click()
      document.body.removeChild(link)
    }
    setIsFetchingCsvData(false)
  }

  return {
    reorderingData: response?.['data']?.['data'] || [],
    reorderFilters: filters,
    pagination: response?.['data']?.['meta']?.['pagination'] || {},
    monthly_column: response?.['data']?.['meta']?.['month_rows'] || {},
    isLoading: isFetching,
    isOpeationLoading: isOpeationLoading,
    isFetchingCsvData: isFetchingCsvData,
    reorderRefetch: refetch,
    onPageChange,
    onSearch,
    onClearAll,
    onClickSorting,
    onDownloadCsv,
    onApplyFilters,
    onResetFilters,
  }
}
